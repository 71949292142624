import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Card,
  CardContent,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { Breadcrumb, FormFlow } from '../../Components';
import { getFormBySlugFluxo, getFluxoFlowBySlug, startRequestFlow, getAnswerForm, getDataIncompletedForm } from '../../services/form-flow';
import {
  FluxoFlow,
  Formulario,
} from '../../interfaces/form-flow';
import StepperCard from './components/StepperCard';
import useStyles from './styles';
import { useHistory, useParams } from 'react-router-dom';
import SignForm from '../../Components/SignForm';
import RequestModal from './components/RequestModal';
import getCookie from '../../utils/getCookies';
import ReloadModal from './components/ReloadModal';

export default function TempForm(): JSX.Element {
  const history = useHistory();
  const { slug, protocolo } = useParams<{ slug: string, protocolo?: string }>();
  const [fluxoFlow, setFluxoFlow] = useState<FluxoFlow>();
  const [forms, setForms] = useState<Formulario[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [startSign, setStartSign] = useState<boolean>(false);
  const [startProtocol, setStartProtocol] = useState<string>();
  const [hasFormStarted, setHasFormStart] = useState<boolean>(false);
  const [formStartedMessage, setFormStartedMessage] = useState<string>('');
  const [formsAnswer, setFormsAnswer] = useState<any[]>([]);
  const [incompletedAnswer, setIncompletedAnswer] = useState<any[]>();
  const [requestError, setRequestError] = useState<any>();
  const [modalReload, setModalReload] = useState<boolean>(false);
  const [hasFetched, setHasFetched] = useState(false);
  // 0, 1, 2
  const [activeStep, setActiveStep] = useState<number>(0);
  const [showModalResult, setShowModalResult] = useState<boolean>(false);
  const [requestMyData, setRequestMyData] = useState<boolean | undefined>(undefined);

  const classes = useStyles();
  const linksBreadcrumb = [
    {
      name: `${fluxoFlow?.titulo || ''} `,
      redirectTo: "#",
    },
  ];

  useEffect(() => {
    getFluxoFlow();
    getFormsFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);


  useEffect(() => {
    if (fluxoFlow && !protocolo) {
      setShowModalResult(fluxoFlow?.terceiros);
      setRequestMyData(!fluxoFlow.terceiros ? true : undefined);
      startFormFlow(fluxoFlow.slug);
      if (protocolo) {
        setShowModalResult(fluxoFlow?.terceiros);
        setRequestMyData(!fluxoFlow.terceiros ? true : undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fluxoFlow, protocolo]);
  const workFlowType = useMemo(() => fluxoFlow?.workflows?.find((item) => item?.tipo === "Entrada"), [fluxoFlow?.workflows])

  const getIncompletoForm = async (count: number = 0, uploadAnwser?: boolean) => {
    const limite = 2;
    setLoading(true);
    const shouldFetch = (fluxoFlow?.tipo_execucao !== "Manual"
      && workFlowType && startProtocol)
      || protocolo || uploadAnwser;
    if (!shouldFetch) {
      setLoading(false);
      return;
    }

    try {
      const { data, status } = await getDataIncompletedForm(protocolo || startProtocol);
      if (data?.retry && fluxoFlow?.tipo_execucao !== "Manual" && workFlowType) {
        setModalReload(true);
        return;
      }

      if (status === 200) {
        if (data?.respostas?.length > 0) {
          setHasFetched(true);

          handleSuccessfulResponse(data.respostas);
        } else {
          handleEmptyResponse(count, limite);
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleSuccessfulResponse = (respostas: any[]) => {
    setIncompletedAnswer(respostas);
    setLoading(false);
  };

  const handleEmptyResponse = (count: number, limite: number) => {
    if (count < limite) {
      setTimeout(() => {
        getIncompletoForm(count + 1);
      }, 1000);
    } else {
      setModalReload(true);
      setLoading(false);
    }
  };

  const handleError = (error: any) => {
    setLoading(false);
    console.error(error);

    if (error.response?.status === 422 && workFlowType) {
      setModalReload(true);
      setRequestError(error.response.data);
    }
  };

  useEffect(() => {
    if (
      !hasFetched &&
      fluxoFlow &&
      (fluxoFlow?.permitir_preenchimento_parcial || (fluxoFlow?.tipo_execucao !== "Manual" && workFlowType) || protocolo)
    ) {
      getIncompletoForm();
    }
    return () => setHasFetched(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protocolo, activeStep, startProtocol, fluxoFlow?.permitir_preenchimento_parcial, fluxoFlow?.tipo_execucao, workFlowType]);
  const getAnswers = async () => {
    let user = JSON.parse(getCookie('gov_user_data') || '{}');
    let formStarted = JSON.parse(getCookie('gov_user_form_started') || '{}');
    setLoading(true);
    try {
      if (user.cpf && formStarted.identificador) {
        const { data } = await getAnswerForm({
          identificador: formStarted.identificador,
          cpf: user.cpf,
        });
        setFormsAnswer(data.results);
      } else {
        setHasFormStart(true);
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  const startFormFlow = async (slug: string) => {
    let user = JSON.parse(getCookie('gov_user_data') || '{}');
    if (user.cpf) {
      try {
        setLoading(true);
        const { status, data } = await startRequestFlow(slug, user.cpf);
        if (data) {
          setStartProtocol(data.protocolo)
          document.cookie = `gov_user_form_started=${JSON.stringify(data)};path=/`;
          if (status === 200) {
            await getAnswers();
          }
        }
      } catch (error) {
        setFormStartedMessage(error?.response?.data?.message || '');
        if (error?.response?.status === 406) {
          setHasFormStart(true);
          sessionStorage.removeItem('gov_user_form_started');
          await getAnswers();
        }
      } finally {
        setLoading(false);
      }
    }
  }

  const getFluxoFlow = async () => {
    setLoading(true);
    try {
      const { data } = await getFluxoFlowBySlug(slug);
      setFluxoFlow(data.results[0]);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }

  const getFormsFlow = async () => {
    setLoading(true);
    try {
      const { data } = await getFormBySlugFluxo(slug);
      setForms(data.results)

    } catch (err) {
    } finally {
      setLoading(false);
    }
  }

  const getOrderByForms = () => {
    return forms.sort((a, b) => {
      if (a.ordem < b.ordem) return -1;
      if (a.ordem > b.ordem) return 1;
      return 0;
    });
  }

  const handleStep = (step: number) => setActiveStep(step);

  const handleModal = () => setShowModalResult(!showModalResult);

  const handleModalReload = () => setModalReload(!modalReload);

  return (
    <Container>
      {modalReload && (
        <ReloadModal
          onClose={handleModalReload}
          open={modalReload}
          onReload={getIncompletoForm}
          slugService={fluxoFlow?.servico_slug}
          requestError={requestError}
          servicoAtivo={fluxoFlow?.ativo} />
      )}
      {!protocolo && (
        <RequestModal
          onClose={handleModal}
          open={showModalResult}
          isForMy={requestMyData}
          setIsForMy={setRequestMyData}
        />
      )}

      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb links={linksBreadcrumb} />
          <Box padding={2}>
            {fluxoFlow && (
              <Typography className={classes.title}>
                <div
                  dangerouslySetInnerHTML={{ __html: fluxoFlow.titulo }}
                />
              </Typography>
            )}
            {fluxoFlow &&
              <Typography className={classes.description}>
                <div
                  dangerouslySetInnerHTML={{ __html: fluxoFlow.descricao }}
                />

              </Typography>
            }
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Card className={classes.mainCard}>
            <CardContent className={classes.cardContent}>
              {loading ? (
                <Box className={classes.boxContent}>
                  <CircularProgress />
                </Box>
              ) : (
                <Box className={classes.boxContent}>
                  <StepperCard itens={forms} activeStep={hasFormStarted ? forms.length : activeStep} />
                  {
                    !startSign ?
                      (<>
                        {!hasFormStarted ? (
                          <FormFlow
                            formData={getOrderByForms()}
                            handleStep={(step) => handleStep(step)}
                            page={activeStep}
                            handleSubmit={(value, error) => {
                              setStartSign(true);
                            }}
                            formsAnswerData={formsAnswer}
                            requestMyData={requestMyData}
                            incompletedAnswer={incompletedAnswer}
                            serviceSlug={fluxoFlow?.slug}
                            loadIncompletedAnswer={getIncompletoForm}
                          />
                        ) : (
                          <>
                            <Typography align='center'>{formStartedMessage} </Typography>
                            <a
                              rel="noopener noreferrer"
                              href="/workspace/meus_servicos"
                              className={classes.buttonForm}
                              style={{ marginTop: 20 }}
                            >
                              Meus serviços
                            </a>
                            <Button style={{ color: '#0F6FB7', width: '100%' }} variant='text' onClick={() => history.push('/')} >Home </Button>
                          </>
                        )}
                      </>) :
                      <SignForm
                        handleStep={(step) => handleStep(step)}
                        page={activeStep}
                        handleSubmit={(value, error) => console.log(value, error)}
                        hasTerms={fluxoFlow?.termo ? fluxoFlow.termo : null}
                        subscriptionType={fluxoFlow?.tipo_assinatura}
                      />}
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
