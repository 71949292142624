import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogContentText,
  Typography,
  Grid,
  Box,
  IconButton,
} from "@material-ui/core";
import { MdClose } from "react-icons/md";
import { makeStyles, Theme } from "@material-ui/core/styles";
import QrCode from "qrcode.react";
import { HistoryI } from "../../../../interfaces/agendamentos";
import dateFormatToBR from "../../../../utils/dateFormat";
import { cpfMask } from "../../../../utils/cpfFormat";

export interface SimpleDialogProps {
  open: boolean;
  schedule: HistoryI;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  textName: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: "24px",
    letterSpacing: "0.1px",
  },
  textCpf: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "24px",
    textTransform: "capitalize",
  },
  textNumberCounter: {
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(24),
    lineHeight: "24px",
    letterSpacing: "0.1px",
  },
  textCounter: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "24px",
    textTansform: "uppercase",
  },
  textUnidade: {
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: theme.typography.pxToRem(23),
    textTransform: "capitalize",
    color: "#737B7D",
    paddingBottom: "20px",
    paddingTop: "20px",
  },
  textService: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: "22px",
    color: "#373F41",
  },
  textOrgan: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "22px",
    color: "#373F41",
    paddingBottom: "20px",
  },
  textDateTime: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "24px",
    color: "#373F41",
  },
  textTicket: {
    paddingTop: "20px",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "22px",
    color: "#373F41",
    paddingBottom: "20px",
  },
  btnPrint: {
    backgroundColor: "#3CD278",
    border: "none",
    color: "#FFFFFF",
    borderRadius: "0",
    width: "60%",
    textTransform: "capitalize",
    padding: "10px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#00a04b",
    },
  },
  boxActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "25px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.0),
    top: theme.spacing(0.0),
    color: "#FFFFFF",
  },
}));

export default function PrintDialog({
  open,
  handleClose,
  schedule,
}: SimpleDialogProps) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" className={classes.boxHeader}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <MdClose style={{ width: 24, height: 24 }} />
        </IconButton>
        <Grid container justify="space-between" alignItems="flex-start">
          <Grid item xs={8}>
            <Typography className={classes.textName}>
              {schedule.user.first_name}
            </Typography>
            <Typography className={classes.textCpf}>
              {schedule.user.username}
            </Typography>
          </Grid>
          <Grid item>
            <Box style={{ width: "75px", textAlign: "center" }}>
              {/* <Typography className={classes.textNumberCounter}>
                {schedule.guiche.ordem}
              </Typography>
              <Typography className={classes.textCounter}>
                GUICHÊ
              </Typography> */}
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ borderRadius: "0" }}>
        <DialogContentText>
          <Grid container>
            <Grid item style={{ paddingBottom: "20px" }}>
              <Typography className={classes.textUnidade}>
                {schedule.unidade.nome.toLowerCase()}
              </Typography>
              <Typography className={classes.textService}>
                {schedule.servico.titulo}
              </Typography>

              <Typography className={classes.textOrgan}>
                {`Órgão: ${schedule.orgao_responsavel}`}
              </Typography>
              {schedule?.protocolo && (
                <Typography className={classes.textOrgan}>
                  Protocolo:
                  <Typography component="strong">
                    {" "}
                    {schedule?.protocolo}
                  </Typography>
                </Typography>
              )}

              <Typography className={classes.textDateTime}>
                Data da solicitação:
                <Typography component="strong">
                  {" "}
                  {` ${dateFormatToBR(schedule.data)}`}
                </Typography>
              </Typography>

              <Typography className={classes.textDateTime}>
                Horário da solicitação:{" "}
                <Typography component="strong">
                  {schedule.hora.slice(0, 5)}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-around" alignItems="flex-start">
            <Grid item xs={12} sm={12} md={8}>
              {schedule.hora_inicio && (
                <>
                  <Typography className={classes.textDateTime}>
                    Data do atendimento:
                    <Typography component="strong">
                      {" "}
                      {` ${dateFormatToBR(schedule.data)}`}
                    </Typography>
                  </Typography>
                  <Typography className={classes.textDateTime}>
                    Horário do atendimento:
                    <Typography component="strong">
                      {" "}
                      {schedule.hora_inicio.slice(0, 5)}
                    </Typography>
                  </Typography>
                </>
              )}

              <Typography
                className={classes.textDateTime}
                style={{ marginBottom: 20 }}
              >
                Dependente:{" "}
                <Typography component="strong">
                  {schedule.dependentes ? "Sim" : "Não"}
                </Typography>
                {schedule.dependentes && (
                  <>
                    <Typography className={classes.textDateTime}>
                      Nome:{" "}
                      <Typography component="strong">
                        {schedule.dependentes.nome}
                      </Typography>
                    </Typography>
                    <Typography className={classes.textDateTime}>
                      CPF:{" "}
                      <Typography component="strong">
                        {cpfMask(schedule.dependentes.cpf)}
                      </Typography>
                    </Typography>
                    <Typography className={classes.textDateTime}>
                      Grau de parentesco:{" "}
                      <Typography component="strong">
                        {schedule.dependentes.grau_parentesco}
                      </Typography>
                    </Typography>
                  </>
                )}
              </Typography>

              <Typography className={classes.textDateTime}>
                Status do atendimento:
                <Typography component="strong"> {schedule.status}</Typography>
              </Typography>
              <Typography className={classes.textTicket}>
                {schedule.ticket}
              </Typography>
              <Typography className={classes.textDateTime}>
                Requisitos:
              </Typography>
              <Box component="div" dangerouslySetInnerHTML={{ __html: schedule?.servico?.requisitos }} />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <QrCode value={`${process.env.REACT_APP_URL}/comprovante/agendamento/${schedule.ticket}`} size={180} />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <Box className={classes.boxActions}>
        <Button
          variant="contained"
          target="_blank"
          href={`/comprovante/agendamento/${schedule.ticket}`}
          color="secondary"
          className={classes.btnPrint}
          onClick={handleClose}
        >
          Imprimir comprovante
        </Button>
      </Box>
    </Dialog>
  );
}
