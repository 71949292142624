import React, { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { sendCodeForSISECI } from '../../../services/auth';
import Header from '../../Header';

interface Props {
  children: JSX.Element;
  window?: () => Window;
}


export default function Dash(props: Props): ReactElement {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const url = (window as any).location.search;
    const { pathname } = location;
    const query = new URLSearchParams(url);
    const code: any = query.get('code');
    if (code) {
      document.cookie = `pathname_redirect_gov=${pathname};path=/`;
      sendCodeForSISECI(code, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header />
      {children}
    </div>
  );
}
