import React, { ReactElement, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { sendCodeForSISECI } from "../../../services/auth";

interface Props {
  children: JSX.Element;
  // eslint-disable-next-line react/require-default-props
  window?: () => Window;
}

export default function BlankLayout(props: Props): ReactElement {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const url = (window as any).location.search;
    const { pathname } = location;
    const query = new URLSearchParams(url);
    const code: any = query.get("code");
    if (code) {
      document.cookie = "pathname_redirect_gov=" + pathname + "; path=/";
      sendCodeForSISECI(code, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const captcha = document.getElementsByClassName('grecaptcha-badge');

    if (captcha && captcha.length && location?.pathname?.includes("mobile")) {
      captcha[0].remove();
    }
  }, []);

  useEffect(() => {
    let interval = null;
    if (location?.pathname?.includes("mobile")) {
      interval = setInterval(() => {
        const element_01 = document.getElementById("chat-window-iframe");
        const element_02 = document.getElementById("userwayAccessibilityIcon");
        const element_user_way = document.querySelector(".userway_buttons_wrapper");

        if (element_01 && element_02) {
          element_01.style.visibility = "hidden";
          element_01.style.opacity = "0";
          element_02.style.visibility = "hidden";
          element_02.style.opacity = "0";
          element_user_way.remove();
          clearInterval(interval);
        }
      }, 100);
    }
    return () => clearInterval(interval);
  }, [location.pathname]);

  return <>{children}</>;
}
