import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Button,
  CircularProgress,
  Link,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import { extractDateFormat, extractHourFormat } from "../../../../utils/dateFormat";
import { MdAttachFile, MdEmail } from "react-icons/md";
import { IoStarSharp } from 'react-icons/io5';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { EvaluationDialog } from "../../../../Components/EvaluationDialog";
import ModalResponse from '../../../MyRequests/components/ModalResponse';
import RequestModalCancel from '../../../MyRequests/components/RequestModalCancel';
import { Star } from "@material-ui/icons";

export interface TypeFilter {
  title: string;
  value: string;
}
export interface RestitutionSelected {
  solicitacao: number;
  id: string;
}

export interface HistoryData {
  id: number;
  descricao: string;
  status: string;
  user: any;
  hash: null | string;
  created_at: string;
  updated_at: string;
}

export interface StatusData {
  anexos_status_demanda: {
    arquivo: string;
    id: number;
    titulo: string;
  }[];
  descricao: string;
  historico_status_demanda: {
    created_at: string;
    descricao: string;
    ordem: number;
    orgao_nome: string;
    status: string;
  }[];
  status: string;
  updated_at: string;
}


const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxIndicatorsBack: {
    width: "100%",
    margin: "20px 0px",
  },
  indicatorsBack: {
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
    display: "flex",
  },
  textCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 16,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      textAlign: "center",
    },
  },
  button: {
    height: 44,
  },
  status: {
    fontSize: 14,
    fontFamily: "Roboto",
    fontWeight: 500,
    textTransform: "capitalize",
    padding: "5px 16px",
    borderRadius: 25,
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      marginBottom: 24,
      alignSelf: "center",
    },
  },
  titleInfo: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    margin: "24px 0px",
  },
  textTable: {
    color: "#333333",
    textAlign: "center",
  },
  attachText: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    textDecoration: "none",
    color: "#0F6FB7",
  },
  attachTextRestitution: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    textDecoration: "none",
    color: "#0F6FB7",
    marginBottom: "5px",
  },
  indicator: {
    width: 0,
    height: 0,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  card: {
    marginTop: 20,
  },
  descriptionText: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  value: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    color: '#1F2937',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  containerEvaluation: {
    display: 'flex',
    gap: 10,
  },
  boxStar: {
    display: 'flex',
    gap: 10,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 24,
    alignItems: 'center',
  },
  starIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20)
  },
  starCountText: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    textAlign: 'left',
    color: '#373F41',
  },
  iconButton: {
    fontSize: theme.typography.pxToRem(25),
    color: '#fff',
    marginRight: '5px'
  },
  buttonAction: {
    padding: '5px 15px',

  },
  containerButtonAction: {
    marginTop: '-70px',
    position: "absolute",
    display: 'flex',
    gap: '20px',
    '@media (max-width: 660px)': {
      marginTop: '10px',
      display: 'flex',
      position: "relative",
      boxSizing: 'border-box',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: '16px',
      paddingRight: '16px',
      justifyContent: 'space-around',
    },
  },
  fixedButtonBox: {
    width: '100%',
    justifyContent: 'end',
    display: 'flex',
  },
  titleFixedButton: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily: 'Roboto',
    fontWeight: 400,
    textTransform: 'uppercase'
  },
  iconRequests: {
    height: 24,
    width: 24,
  },
  iconRequestsRestitution: {
    height: 20,
    width: 20,
  },
  textLink: {
    margin: "5px 0px 5px 14px",
    textDecoration: "none",
    color: "#0F6FB7",
  },
  textLinkRestitution: {
    textDecoration: "none",
    color: "#0F6FB7",
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgba(15, 111, 183, 0.05)",
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      fontWeight: 500,
      fontFamily: "Roboto",
    },
    body: {
      fontSize: 14,
      color: "#333333",
    },
  })
)(TableCell);

const listStatusHistory = [
  'Aguardando',
  'Em Andamento',
  'Arquivado',
  'Cancelado',
  'Encaminhado',
  'Em Aberto',
  'Complementação Cidadão',
  'Reaberto',
  'Recebido',
  'Concluído',
  'Solicitado',
  'Respondido',
  'Respondido(Reaberto)',
  'Restituído',
  'Complementação Cidadão'
]

export default function FlowInfo({ list, getFlowList, avaliacaoPendente, requestFlow }): JSX.Element {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showModalResponse, setShowModalResponse] = useState<boolean>(false);
  const [showModalCancel, setShowModalCancel] = useState<boolean>(false);
  const [restitutionSelected, setRestitutionSelected] = useState<RestitutionSelected | null>();
  const [descriptionRestitution, setDescriptionRestitution] = useState<string | null>();
  const [requestId, setRequestId] = useState<number | null>(null);
  const [ticket, setTicket] = useState<number | null>();
  const matchesMobile = useMediaQuery("(min-width:660px)");
  const history = useHistory();
  const [openDialogEvaluation, setOpenDialogEvaluation] = useState(false);
  const [isPermittedEvaluate, setIsPermittedEvaluate] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseDialog = useCallback(() => {
    setOpenDialogEvaluation(false);
  }, []);


  const handleOpenModalResponse = useCallback(() => {
    setShowModalResponse((oldShowModal) => !oldShowModal);
  }, []);

  const handleGetDescriptionRestitution = useCallback((historic: HistoryData[]) => {
    let descriptionRestitution = historic.find((item) => item.status === 'Restituído')?.descricao;
    setDescriptionRestitution(descriptionRestitution);
  }, []);

  const handleCloseMenuAndDialog = () => {
    setAnchorEl(null);
    setOpenDialogEvaluation(true)
  };
  useEffect(() => {
    if (avaliacaoPendente?.[0]?.pendente) {
      setIsPermittedEvaluate(true)
      setTicket(avaliacaoPendente?.[0]?.id)
    }
  }, [avaliacaoPendente])

  const contentEvaluation = useCallback((avaliacaoPendente) => {
    return (
      <Fragment>
        {!!avaliacaoPendente ? (
          <>
            {!avaliacaoPendente?.[0]?.pendente ? (
              <Box className={classes.containerEvaluation}>
                <Box>
                  <Typography className={classes.titleInfo}>
                    Avaliação do Serviço
                  </Typography>
                  <span className={classes.boxStar}>
                    <Star className={classes.starIcon} />
                    <Typography className={classes.starCountText}>
                      {avaliacaoPendente?.[0]?.avaliacao_consumo || '0'} estrelas
                    </Typography>
                  </span>
                </Box>
              </Box>
            ) : (
              <Typography className={classes.titleInfo}>
                Avaliação pendente
              </Typography>
            )}
          </>
        ) : (
          <Typography className={classes.titleInfo}>
            Avaliação indisponível
          </Typography>
        )}
      </Fragment>
    )
  }, [classes]);

  const restitutionDocument = (historyID: number) => {
    const restituicao = list?.results[0]?.restituicao.find(item => item.historico === historyID)
    if (restituicao.anexos_restituicao.length > 0) {
      return `${restituicao.anexos_restituicao[0].arquivo}`
    }
    return null
  }
  const handleModal = useCallback(() =>
    setShowModalCancel((oldModal) => !oldModal), []);

  const isPermittedCancel = useMemo(() => !(list?.results[0]?.status === 'Concluído' ||
    list?.results[0]?.status === 'Arquivado' ||
    list?.results[0]?.status === 'Cancelado'), [list?.results]);

  return (
    <>
      <Container>
        <Card className={classes.card}>
          {!list ? (
            <Box
              marginBottom="60px"
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box className={classes.fixedButtonBox}>
                <Box className={classes.containerButtonAction}>
                  {(list.results[0]?.avaliacao_solicitacao?.pendente || isPermittedCancel) && (
                    list.results[0].restituicao?.map((restituicao) => {
                      if (!restituicao.respondido) {
                        return (
                          <Button
                            variant="contained"
                            color="primary" className={classes.buttonAction} onClick={() => {
                              handleOpenModalResponse();
                              handleGetDescriptionRestitution(list.results[0].historico_flow_resposta);
                              setRequestId(list.results[0].id);
                              setRestitutionSelected({
                                solicitacao: restituicao.solicitacao,
                                id: restituicao.id,
                              })
                            }} >
                            <MdEmail className={classes.iconButton} />
                            <Typography className={classes.titleFixedButton}
                            >
                              Responder
                            </Typography>
                          </Button>
                        )
                      }
                    })
                  )}
                  {list?.results[0]?.flow?.permitir_retificacao && list?.results[0]?.flow?.ativo &&
                    (<>
                      <Button
                        variant="contained"
                        color="primary" className={classes.buttonAction} onClick={() => history.push(`/workspace/formularios/${list?.results[0]?.flow?.slug}`)} >
                        <MdOutlineModeEditOutline display={matchesMobile ? '' : 'none'} className={classes.iconButton} />

                        <Typography className={classes.titleFixedButton}
                        >
                          Retificar
                        </Typography>
                      </Button>
                    </>)}
                  {isPermittedEvaluate &&
                    (<>
                      <Button
                        variant="contained"
                        color="primary" className={classes.buttonAction} onClick={() => history.push(`/workspace/avaliar/${ticket}`)} >
                        <IoStarSharp display={matchesMobile ? '' : 'none'} className={classes.iconButton} />

                        <Typography className={classes.titleFixedButton}
                        >
                          Avaliar
                        </Typography>
                      </Button>
                    </>)}
                </Box>
              </Box>
              {list?.results.length && !loading ? (
                <>
                  <TableContainer
                    component={Paper}
                  >
                    <Table aria-label="customized table">
                      <TableBody>
                        {list.results.map((request) => (
                          <Fragment key={request.protocolo}>

                            <TableRow>
                              <StyledTableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={6}
                              >
                                <Box>
                                  <Typography className={classes.titleInfo}>
                                    Informações
                                  </Typography>
                                  <Table
                                    size="small"
                                    aria-label="purchases"
                                    style={{ marginBottom: 30 }}
                                  >
                                    <TableHead>
                                      <TableRow
                                        style={{ textTransform: "uppercase" }}
                                      >
                                        <TableCell
                                          className={classes.textTable}
                                        >
                                          Protocolo
                                        </TableCell>
                                        <TableCell
                                          className={classes.textTable}
                                        >
                                          serviço
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow
                                        style={{ textTransform: "uppercase" }}
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          className={classes.textTable}
                                        >
                                          {request.protocolo}
                                        </TableCell>
                                        <TableCell
                                          className={classes.textTable}
                                        >
                                          {request.servico_titulo}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                  {requestFlow?.motivo_integracao_externa?.length > 0 && (
                                    <>
                                      <Typography className={classes.titleInfo}>
                                        Motivo
                                      </Typography>
                                      <Typography className={classes.descriptionText}>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: requestFlow?.motivo_integracao_externa
                                          }}
                                        />
                                      </Typography>
                                    </>
                                  )}
                                  <Typography className={classes.titleInfo}>
                                    Solicitação
                                  </Typography>
                                  <Typography className={classes.descriptionText}>
                                    <div
                                      dangerouslySetInnerHTML={{ __html: request?.conteudo }}
                                    />
                                  </Typography>
                                  <Box style={{ margin: "20px 0px" }}>
                                    <Typography className={classes.titleInfo}>
                                      Documentos
                                    </Typography>
                                    {request?.documento_resposta?.map(
                                      (document, index) => (
                                        <Typography
                                          className={classes.attachText}
                                          key={index.toString()}
                                        >
                                          <MdAttachFile
                                            className={classes.iconRequests}
                                          />
                                          <Link
                                            href={`/workspace/meus_servicos/view_doc/${document.id}/${request.identificador}`}
                                            target="_blanck"
                                            className={classes.textLink}
                                          >
                                            {document.assunto}
                                          </Link>
                                        </Typography>
                                      )
                                    )}

                                    {request?.documento_resposta?.length ===
                                      0 && (
                                        <Typography
                                          className={classes.attachText}
                                        >
                                          Sem documentos
                                        </Typography>
                                      )}

                                  </Box>
                                  {request?.anexo_documento_resposta.length > 0 && (<>
                                    <Box style={{ margin: "20px 0px" }}>
                                      <Typography className={classes.titleInfo}>
                                        Anexos recebidos
                                      </Typography>
                                      {request?.anexo_documento_resposta
                                        ?.map(
                                          (document, index) => (
                                            <Typography
                                              className={classes.attachText}
                                              key={index.toString()}
                                            >
                                              <MdAttachFile
                                                className={classes.iconRequests}
                                              />
                                              <Link
                                                href={`${document.arquivo}`}
                                                target="_blanck"
                                                className={classes.textLink}
                                              >
                                                {document.assunto}
                                              </Link>
                                            </Typography>
                                          )
                                        )}

                                      {request?.anexo_documento_resposta
                                        ?.length ===
                                        0 && (
                                          <Typography
                                            className={classes.attachText}
                                          >
                                            Sem documentos
                                          </Typography>
                                        )}
                                    </Box>
                                  </>)}


                                  <Box>
                                    <Typography className={classes.titleInfo}>
                                      Histórico
                                    </Typography>
                                    <Table
                                      size="small"
                                      aria-label="purchases"
                                      style={{ marginBottom: 30 }}
                                    >
                                      <TableHead>
                                        <TableRow
                                          style={{
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          <TableCell
                                            className={classes.textTable}
                                          >
                                            ordem
                                          </TableCell>
                                          <TableCell
                                            className={classes.textTable}
                                          >
                                            status
                                          </TableCell>
                                          <TableCell
                                            className={classes.textTable}
                                          >
                                            enviado
                                          </TableCell>
                                          <TableCell
                                            className={classes.textTable}
                                          >
                                            descrição
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {request?.historico_flow_resposta?.map(
                                          (history, index) => (
                                            <TableRow
                                              key={`${history.hash}-${index}`}
                                              style={{
                                                textTransform: "uppercase",
                                              }}
                                            >
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                className={classes.textTable}
                                                key={index.toString()}
                                              >
                                                {index + 1}
                                              </TableCell>
                                              {!listStatusHistory.includes(history?.status) && (
                                                <StyledTableCell align="center">
                                                  <Typography
                                                    className={classes.status}
                                                    style={{
                                                      color: "#333",
                                                      border:
                                                        "1px solid rgba(5, 51, 150, 0.05)",
                                                      background:
                                                        " rgba(5, 51, 150, 0.05)",
                                                    }}
                                                  >
                                                    {history.status}
                                                  </Typography>
                                                </StyledTableCell>
                                              )}
                                              {history?.status ===
                                                "Aguardando" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#FAAD13",
                                                        border:
                                                          "1px solid rgba(250, 173, 19, 0.05)",
                                                        background:
                                                          "rgba(250, 173, 19, 0.05)",
                                                      }}
                                                    >
                                                      Aguardando
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Em Andamento" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#FAAD13",
                                                        border:
                                                          "1px solid rgba(250, 173, 19, 0.05)",
                                                        background:
                                                          "rgba(250, 173, 19, 0.05)",
                                                      }}
                                                    >
                                                      Em Andamento
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Arquivado" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#F03D3D",
                                                        border:
                                                          "1px solid rgba(240, 61, 61, 0.08)",
                                                        background:
                                                          "rgba(240, 61, 61, 0.08)",
                                                      }}
                                                    >
                                                      Arquivado
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Cancelado" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#F03D3D",
                                                        border:
                                                          "1px solid rgba(240, 61, 61, 0.08)",
                                                        background:
                                                          "rgba(240, 61, 61, 0.08)",
                                                      }}
                                                    >
                                                      Cancelado
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Encaminhado" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Encaminhado
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Em Aberto" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Em Aberto
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Complementação Cidadão" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Complementação Cidadão
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Reaberto" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Reaberto
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Recebido" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Recebido
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Concluído" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#3CD278",
                                                        border:
                                                          "1px solid rgba(60, 210, 120, 0.10)",
                                                        background:
                                                          "rgba(60, 210, 120, 0.10)",
                                                      }}
                                                    >
                                                      Concluído
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Solicitado" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Solicitado
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Respondido" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#3CD278",
                                                        border:
                                                          "1px solid rgba(60, 210, 120, 0.05)",
                                                        background:
                                                          "rgba(60, 210, 120, 0.05)",
                                                      }}
                                                    >
                                                      Respondido
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Respondido(Reaberto)" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#3CD278",
                                                        border:
                                                          "1px solid rgba(60, 210, 120, 0.05)",
                                                        background:
                                                          "rgba(60, 210, 120, 0.05)",
                                                      }}
                                                    >
                                                      Respondido(Reaberto)
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history?.status ===
                                                "Restituído" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#06AED4",
                                                        border:
                                                          "1px solid rgba(60, 210, 120, 0.10)",
                                                        background:
                                                          "rgb(6, 174,212, 0.10)",
                                                      }}
                                                    >
                                                      Restituído
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              {history.status ===
                                                "Complementação Cidadão" && (
                                                  <StyledTableCell align="center">
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Complementação Cidadão
                                                    </Typography>
                                                  </StyledTableCell>
                                                )}
                                              <TableCell
                                                className={classes.textTable}
                                              >
                                                {`${extractDateFormat(
                                                  history?.created_at
                                                )} às ${extractHourFormat(
                                                  history?.created_at
                                                )}`}
                                              </TableCell>
                                              <TableCell
                                                className={classes.textTable}
                                              >
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      history?.descricao ||
                                                      "Sem informação",
                                                  }}
                                                />
                                                {history.status ===
                                                  "Restituído" && restitutionDocument(history.id) && (<>
                                                    <Typography
                                                      className={classes.attachTextRestitution}
                                                      key={index.toString()}
                                                    >
                                                      <MdAttachFile
                                                        className={classes.iconRequestsRestitution}
                                                      />
                                                      <Link
                                                        href={restitutionDocument(history.id)}
                                                        target="_blanck"
                                                        className={classes.textLinkRestitution}
                                                      >
                                                        Ver anexo
                                                      </Link>
                                                    </Typography>
                                                  </>)}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}

                                        {request?.historico_flow_resposta?.length === 0 && (
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            colSpan={4}
                                            className={classes.textTable}
                                          >
                                            {"Sem histórico"}
                                          </TableCell>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                  {contentEvaluation(avaliacaoPendente)}
                                </Box>
                              </StyledTableCell>
                            </TableRow>
                          </Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Box
                  marginBottom="60px"
                  minHeight="100px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  <Typography style={{ textAlign: "center" }} variant="h4">
                    Nenhum resultado foi encontrado.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Card>
        {avaliacaoPendente &&
          (<>
            <EvaluationDialog
              open={openDialogEvaluation}
              handleCloseOrOpenDialog={handleCloseDialog}
              type={avaliacaoPendente?.tipo_demanda === "Solicitação" ? 'solicitacao_servico' : avaliacaoPendente?.tipo_demanda === 'Manifestação' ? 'manifestacao' : avaliacaoPendente?.tipo_demanda === 'E-SIC' ? 'esic' : 'agendamento'}
              evaluationIndicator={avaliacaoPendente?.id}
            />
          </>)}
      </Container >
      {showModalResponse && restitutionSelected && descriptionRestitution && (
        <ModalResponse
          handleClose={handleOpenModalResponse}
          open={showModalResponse}
          restitutionData={restitutionSelected}
          legend={descriptionRestitution}
          onReload={getFlowList}
        />
      )}
      {showModalCancel && requestId && (
        <RequestModalCancel
          handleClose={handleModal}
          open={showModalCancel}
          requestId={requestId}
          onReload={getFlowList}
        />
      )}
    </>
  );
}
