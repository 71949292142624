import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  TableContainer,
  Container,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Collapse,
  CircularProgress,
  Button,
  TableHead,
  Link,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { IoStarSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { format } from 'date-fns';
import { Star } from '@material-ui/icons';
import ModalResponse from '../../../MyEsic/components/ModalResponse';
import { EvaluationDialog } from '../../../../Components/EvaluationDialog';
import ModalResponseRestituicao from '../../../MyEsic/components/ModalResponseRestituicao';
import { RestitutionSelected } from '../../../MyEsic';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#d3d3d3",
      },
    },
    "& .MuiSelect-iconOutlined": {
      height: 24,
      width: 24,
    },
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxIndicatorsBack: {
    width: "100%",
    margin: "20px 0px",
  },
  indicatorsBack: {
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
    display: 'flex',
  },
  textCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 16,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      textAlign: "center",
    },
  },
  button: {
    height: 56,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.7',
    }
  },
  status: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 500,
    textTransform: 'capitalize',
    padding: '5px 16px',
    borderRadius: 25,
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
      alignSelf: 'center',
    },
  },
  titleInfo: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    margin: '24px 0px'
  },
  textTable: {
    color: '#333333',
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    color: '#1F2937',
    marginBottom: 10,
  },
  value: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    color: '#1F2937',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  boxStatus: {
    alignContent: 'center',
    maxWidth: 150,
    textAlign: 'center',
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    color: '#1F2937',
    fontWeight: 400,
    lineHeight: '20.02px',
  },
  openModalLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: '20.02px',
  },
  icon: {
    width: 18,
    height: 18,
    color: '#053396',
  },
  input: {
    color: '#333',
  },
  containerEvaluation: {
    display: 'flex',
    gap: 10,
  },
  boxStar: {
    display: 'flex',
    gap: 10,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 24,
    alignItems: 'center',
  },
  starIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20)
  },
  starCountText: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    textAlign: 'left',
    color: '#373F41',
  },
  iconButton: {
    fontSize: theme.typography.pxToRem(25),
    color: '#fff',
    marginRight: '5px'
  },
  buttonAction: {
    padding: '5px 15px'
  },
  fixedButtonBox: {
    width: '100%',
    justifyContent: 'end',
    display: 'flex',
    marginTop: '-70px',
    paddingBottom: '100px',
    gap: '50px',
  },
  titleFixedButton: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily: 'Roboto',
    fontWeight: 400,
    textTransform: 'uppercase'
  }
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgba(15, 111, 183, 0.05)",
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 500,
      fontFamily: 'Roboto',
    },
    body: {
      fontSize: 14,
      color: '#333333'
    }
  })
)(TableCell);




const EsicInfo = ({ list, getEsicList, avaliacaoPendente }) => {
  const classes = useStyles();

  const [showModalResponse, setShowModalResponse] = useState<boolean>(false);
  const [esicId, setEsicId] = useState<number | null>();
  const [showDetailsTable, setShowDetailsTable] = useState<number | null>();
  const [showDetailsTableResource, setShowDetailsTableResource] = useState<number | null>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [ticket, setTicket] = useState<number | null>();
  const history = useHistory();


  const [openDialogEvaluation, setOpenDialogEvaluation] = useState(false);
  const [isPermittedEvaluate, setIsPermittedEvaluate] = useState(false)

  const [showModalResponseRestituicao, setShowModalResponseRestituicao] = useState<boolean>(false);
  const [restitutionSelected, setRestitutionSelected] = useState<RestitutionSelected | null>();
  const [descriptionRestitution, setDescriptionRestitution] = useState<string | null>();
  const handleOpenModalResponseRestituicao = useCallback(() => {
    setShowModalResponseRestituicao((oldShowModal) => !oldShowModal);
  }, []);

  const handleGetDescriptionRestitution = useCallback((status: any) => {
    let descriptionRestitution = status?.status === 'Complementação Cidadão' && status.descricao;
    setDescriptionRestitution(descriptionRestitution);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialogEvaluation(false);
  }, []);

  const handleCloseMenuAndDialog = () => {
    setAnchorEl(null);
    setOpenDialogEvaluation(true)
  };

  const handleOpenModalResponse = useCallback(() => {
    setShowModalResponse((oldShowModal) => !oldShowModal);
  }, []);

  const expandTable = useCallback(
    (index: number) => {
      if (showDetailsTable === index) {
        setShowDetailsTable(null);
      } else {
        setShowDetailsTable(index);
      }
    },
    [showDetailsTable],
  );


  const expandTableResource = useCallback(
    (index: number) => {
      if (showDetailsTableResource === index) {
        setShowDetailsTableResource(null);
      } else {
        setShowDetailsTableResource(index);
      }
    },
    [showDetailsTableResource],
  );

  const contentEvaluation = useCallback((avaliacaoPendente) => {
    return (
      <Fragment>
        {!!avaliacaoPendente ? (
          <>
            {!avaliacaoPendente?.[0]?.pendente ? (
              <Box className={classes.containerEvaluation}>
                <Box>
                  <Typography className={classes.titleInfo}>
                    Avaliação do Serviço
                  </Typography>
                  <span className={classes.boxStar}>
                    <Star className={classes.starIcon} />
                    <Typography className={classes.starCountText}>
                      {avaliacaoPendente?.[0]?.avaliacao_consumo || '0'} estrelas
                    </Typography>
                  </span>
                </Box>
              </Box>
            ) : (
              <Typography className={classes.titleInfo}>
                Avaliação pendente
              </Typography>
            )}
          </>
        ) : (
          <Typography className={classes.titleInfo}>
            Avaliação indisponível
          </Typography>
        )}
      </Fragment>
    )
  }, [classes]);

  const isPermittedCancel = useMemo(() => !(list?.results[0]?.status_esic?.status === 'Concluído' ||
    list?.results[0]?.status_esic?.status === 'Arquivado' ||
    list?.results[0]?.status_esic?.status === 'Cancelado'), [list?.results[0]?.status_esic]);

  const isPermittedResponseResource = useMemo(() =>
    (list?.results[0]?.status_esic?.status === 'Concluído' && list?.results[0]?.status_esic?.recursos_esic?.length === 0) ||
    (list?.results[0]?.status_esic?.recursos_esic?.find((recurso) => recurso.resposta !== null) && list?.results[0]?.status_esic?.recursos_esic?.length <= 2 && list?.results[0]?.status_esic?.status === 'Concluído'),
    [list?.results[0]?.status_esic]);

  const hasDecision = (esic: any) => esic?.status_esic?.decisao && (esic?.status_esic?.status === 'Arquivado' || esic?.status_esic?.status === 'Concluído');

  useEffect(() => {
    if (avaliacaoPendente?.[0]?.pendente) {
      setIsPermittedEvaluate(true)
      setTicket(avaliacaoPendente?.[0]?.id)
    }
  }, [avaliacaoPendente])

  return (
    <>
      <Container>
        {!list ? (
          <Box
            marginBottom="60px"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>

            <Box className={classes.fixedButtonBox} >
              {(list?.results[0]?.avaliacao_esic?.pendente || isPermittedCancel) && isPermittedResponseResource && (list?.results[0]?.tipo_demanda !== "Recurso (última instância)" && list?.results[0]?.status_esic?.status == "Concluído") && (
                <>
                  {list?.results[0].tipo_demanda === 'Recurso' ?
                    (<>
                      <Link
                        underline='none'
                        href={`/esic/${list.results[0].protocolo}/Recurso-ultima-instancia`}>
                        <Button
                          variant="contained"
                          color="primary" className={classes.buttonAction} >

                          <MdEmail className={classes.iconButton} />
                          <Typography className={classes.titleFixedButton}
                          >
                            Enviar recurso (última instância)
                          </Typography>
                        </Button>
                      </Link>
                    </>) :
                    (<>
                      <Link
                        underline='none'
                        href={`/esic/${list.results[0].protocolo}/Recurso`}>
                        <Button
                          variant="contained"
                          color="primary" className={classes.buttonAction} >

                          <MdEmail className={classes.iconButton} />
                          <Typography className={classes.titleFixedButton}
                          >
                            Enviar recurso
                          </Typography>
                        </Button>
                      </Link>
                    </>)}

                </>

              )}

              {list.results[0].status_esic.restituicao_esic.map((restituicao => {
                if (!restituicao.respondido) {
                  return (
                    <>
                      <Button
                        variant="contained"
                        color="primary" className={classes.buttonAction} onClick={() => {
                          handleOpenModalResponseRestituicao();
                          handleGetDescriptionRestitution(list.results[0].status_esic);
                          setEsicId(list.results[0].id);
                          setRestitutionSelected({
                            solicitacao: restituicao.historico,
                            id: restituicao.id,
                          })
                        }} >
                        <MdEmail className={classes.iconButton} />

                        <Typography className={classes.titleFixedButton}
                        >
                          Responder
                        </Typography>
                      </Button>
                    </>
                  )
                }
              }))}
              {isPermittedEvaluate &&
                (<>
                  <Button
                    variant="contained"
                    color="primary" className={classes.buttonAction} onClick={() => history.push(`/workspace/avaliar/${ticket}`)} >
                    <IoStarSharp className={classes.iconButton} />

                    <Typography className={classes.titleFixedButton}
                    >
                      Avaliar
                    </Typography>
                  </Button>
                </>)}
              {/*(list?.results[0]?.avaliacao_esic && list?.results[0]?.avaliacao_esic?.pendente) && (

                <Button
                  variant="contained"
                  color="primary" className={classes.buttonAction} onClick={handleCloseMenuAndDialog} >
                  <IoStarSharp className={classes.iconButton} />

                  <Typography className={classes.titleFixedButton}
                  >
                    Avaliar
                  </Typography>
                </Button>
              )*/}
            </Box>

            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>
                  {list?.results?.map((esic) => (
                    <Fragment key={esic.id}>
                      <TableRow>
                        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <Box>
                            <Typography className={classes.titleInfo}>
                              Informações
                            </Typography>
                            <Table
                              size="small"
                              aria-label="purchases"
                              style={{ marginBottom: 30 }}
                            >
                              <TableHead>
                                <TableRow
                                  style={{ textTransform: "uppercase" }}
                                >
                                  <TableCell
                                    className={classes.textTable}
                                  >
                                    Protocolo
                                  </TableCell>
                                  <TableCell
                                    className={classes.textTable}
                                  >
                                    status
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow
                                  style={{ textTransform: "uppercase" }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    className={classes.textTable}
                                    style={{ width: '50%' }}
                                  >
                                    {esic.protocolo}
                                  </TableCell>
                                  <TableCell
                                    className={classes.textTable}
                                    style={{ width: '50%' }}

                                  >
                                    {esic.status_esic.status}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>

                            <Box>
                              <Typography className={classes.textTable}>
                                Texto do pedido
                              </Typography>
                              <p>
                                {esic?.descricao ? esic.descricao : (<span className={classes.value}>Sem descrição</span>)}
                              </p>
                            </Box>
                            <Box>
                              <Typography className={classes.textTable}>
                                Anexo
                              </Typography>
                              {esic?.anexos_esic?.length > 0 ? (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gridGap="10px"
                                >
                                  {esic?.anexos_esic.map((anexo) => (
                                    <a
                                      className={classes.value}
                                      key={anexo.id}
                                      href={anexo.arquivo}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {anexo.titulo}
                                    </a>
                                  ))}
                                </Box>
                              ) : (
                                <span className={classes.value}>Sem anexos</span>
                              )}
                            </Box>
                            {esic.complemento &&
                              (<>
                                <Box>
                                  <Typography className={classes.textTable} style={{ marginTop: '20px' }}>
                                    Solicitação
                                  </Typography>
                                  {esic?.complemento}
                                </Box>
                              </>)}
                            {hasDecision(esic) && (
                              <Box>
                                <Typography className={classes.textTable} style={{ marginTop: '20px' }}>
                                  Decisão
                                </Typography>
                                {esic?.status_esic?.decisao}
                              </Box>
                            )}

                            <Box>
                              <Typography className={classes.titleInfo}>
                                Histórico de pedidos
                              </Typography>
                              <Table size="small" aria-label="purchases" style={{ marginBottom: 30 }}>
                                {esic?.status_esic?.historico_status_esic?.map(
                                  (historico, index) => (
                                    <Fragment key={`${historico.ordem}-key`}>
                                      <TableRow>
                                        <StyledTableCell align="left">
                                          {historico.ordem}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {`${format(
                                            new Date(historico.created_at),
                                            'dd/MM/yyyy',
                                          )} às ${format(
                                            new Date(historico.created_at),
                                            'HH:mm',
                                          )}`}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {historico.orgao_nome ?? 'Teste'}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {
                                            historico.status === 'Aguardando' && (
                                              <Box
                                                className={classes.boxStatus}
                                              >
                                                <Typography
                                                  className={classes.status}
                                                  style={{
                                                    color: '#FAAD13',
                                                    border: '1px solid rgba(250, 173, 19, 0.05)',
                                                    background: 'rgba(250, 173, 19, 0.05)',
                                                  }}
                                                >
                                                  Aguardando
                                                </Typography>
                                              </Box>
                                            )
                                          }
                                          {
                                            historico.status === 'Em Andamento' && (
                                              <Box
                                                className={classes.boxStatus}
                                              >
                                                <Typography
                                                  className={classes.status}
                                                  style={{
                                                    color: '#FAAD13',
                                                    border: '1px solid rgba(250, 173, 19, 0.05)',
                                                    background: 'rgba(250, 173, 19, 0.05)',
                                                  }}
                                                >
                                                  Em Andamento
                                                </Typography>
                                              </Box>
                                            )
                                          }
                                          {
                                            historico.status === 'Prorrogado' && (
                                              <Box
                                                className={classes.boxStatus}
                                              >
                                                <Typography
                                                  className={classes.status}
                                                  style={{
                                                    color: '#FAAD13',
                                                    border: '1px solid rgba(250, 173, 19, 0.10)',
                                                    background: 'rgba(250, 173, 19, 0.10)',
                                                  }}
                                                >
                                                  Prorrogado
                                                </Typography>
                                              </Box>
                                            )
                                          }
                                          {historico.status === 'Arquivado' && (
                                            <Box
                                              className={classes.boxStatus}
                                            >
                                              <Typography
                                                className={classes.status}
                                                style={{
                                                  color: '#F03D3D',
                                                  border: '1px solid rgba(240, 61, 61, 0.08)',
                                                  background: 'rgba(240, 61, 61, 0.08)',
                                                }}
                                              >
                                                Arquivado
                                              </Typography>
                                            </Box>
                                          )}
                                          {historico.status === 'Cancelado' && (
                                            <Box
                                              className={classes.boxStatus}
                                            >
                                              <Typography
                                                className={classes.status}
                                                style={{
                                                  color: '#F03D3D',
                                                  border: '1px solid rgba(240, 61, 61, 0.08)',
                                                  background: 'rgba(240, 61, 61, 0.08)',
                                                }}
                                              >
                                                Cancelado
                                              </Typography>
                                            </Box>
                                          )}
                                          {historico.status === 'Encaminhado' && (
                                            <Box
                                              className={classes.boxStatus}
                                            >
                                              <Typography
                                                className={classes.status}
                                                style={{
                                                  color: '#053396',
                                                  border: '1px solid rgba(5, 51, 150, 0.05)',
                                                  background: ' rgba(5, 51, 150, 0.05)',
                                                }}
                                              >
                                                Encaminhado
                                              </Typography>
                                            </Box>
                                          )}
                                          {historico.status === 'Em Aberto' && (
                                            <Box
                                              className={classes.boxStatus}
                                            >
                                              <Typography
                                                className={classes.status}
                                                style={{
                                                  color: '#053396',
                                                  border: '1px solid rgba(5, 51, 150, 0.05)',
                                                  background: ' rgba(5, 51, 150, 0.05)',
                                                }}
                                              >
                                                Em Aberto
                                              </Typography>
                                            </Box>
                                          )}
                                          {historico.status === 'Recebido' && (
                                            <Box
                                              className={classes.boxStatus}
                                            >
                                              <Typography
                                                className={classes.status}
                                                style={{
                                                  color: '#053396',
                                                  border: '1px solid rgba(5, 51, 150, 0.05)',
                                                  background: ' rgba(5, 51, 150, 0.05)',
                                                }}
                                              >
                                                Recebido
                                              </Typography>
                                            </Box>
                                          )}
                                          {historico.status === 'Respondido' && (
                                            <Box
                                              className={classes.boxStatus}
                                            >
                                              <Typography
                                                className={classes.status}
                                                style={{
                                                  color: '#3CD278',
                                                  border: '1px solid rgba(60, 210, 120, 0.05)',
                                                  background: 'rgba(60, 210, 120, 0.05)',
                                                }}
                                              >
                                                Respondido
                                              </Typography>
                                            </Box>
                                          )}
                                          {historico.status === 'Concluído' && (
                                            <Box
                                              className={classes.boxStatus}
                                            >
                                              <Typography
                                                className={classes.status}
                                                style={{
                                                  color: '#3CD278',
                                                  border: '1px solid rgba(60, 210, 120, 0.05)',
                                                  background: 'rgba(60, 210, 120, 0.05)',
                                                }}
                                              >
                                                Concluído
                                              </Typography>
                                            </Box>
                                          )}
                                          {historico.status === 'Reaberto' && (
                                            <Box
                                              className={classes.boxStatus}
                                            >
                                              <Typography
                                                className={classes.status}
                                                style={{
                                                  color: '#053396',
                                                  border: '1px solid rgba(5, 51, 150, 0.20)',
                                                  background: ' rgba(5, 51, 150, 0.20)',
                                                }}
                                              >
                                                Reaberto
                                              </Typography>
                                            </Box>
                                          )}
                                          {historico?.status ===
                                            "Complementação Cidadão" && (
                                              <Box
                                                className={classes.boxStatus}
                                              >
                                                <Typography
                                                  className={classes.status}
                                                  style={{
                                                    color: "#053396",
                                                    border:
                                                      "1px solid rgba(5, 51, 150, 0.05)",
                                                    background:
                                                      " rgba(5, 51, 150, 0.05)",
                                                  }}
                                                >
                                                  Complementação Cidadão
                                                </Typography>
                                              </Box>
                                            )}
                                          {historico.status === 'Recurso' && (
                                            <Box
                                              className={classes.boxStatus}
                                            >
                                              <Typography
                                                className={classes.status}
                                                style={{
                                                  color: '#053396',
                                                  border: '1px solid rgba(5, 51, 150, 0.20)',
                                                  background: ' rgba(5, 51, 150, 0.20)',
                                                }}
                                              >
                                                Recurso
                                              </Typography>
                                            </Box>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => expandTable(index)}
                                          >
                                            {showDetailsTable === index ? (
                                              <MdExpandLess />
                                            ) : (
                                              <MdExpandMore />
                                            )}
                                          </IconButton>
                                        </StyledTableCell>
                                      </TableRow>
                                      <TableRow>
                                        <StyledTableCell
                                          style={{ paddingBottom: 0, paddingTop: 0 }}
                                          colSpan={6}
                                        >
                                          <Collapse
                                            in={index === showDetailsTable}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <Box margin="10px 0px">
                                              <Typography className={classes.titleInfo}>
                                                Descrição
                                              </Typography>
                                              <Typography className={classes.description}>
                                                {historico.descricao || 'Sem resultados.'}
                                              </Typography>
                                              {(historico.status === 'Concluído' || historico.status === 'Reaberto')
                                                && esic?.status_esic?.anexos_status_esic?.length > 0 && (
                                                  <>
                                                    <Typography
                                                      className={classes.titleInfo}
                                                    >
                                                      Anexos
                                                    </Typography>
                                                    <Box
                                                      display="flex"
                                                      flexDirection="column"
                                                      alignItems="flex-start"
                                                      style={{
                                                        gap: 10,
                                                      }}
                                                    >
                                                      {esic?.status_esic?.anexos_status_esic?.map(
                                                        (anexo) => (
                                                          <a
                                                            href={anexo.arquivo}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                          >
                                                            {anexo.titulo}
                                                          </a>
                                                        ),
                                                      )}
                                                    </Box>
                                                  </>
                                                )}
                                            </Box>
                                          </Collapse>
                                        </StyledTableCell>
                                      </TableRow>
                                    </Fragment>
                                  ),
                                )}
                              </Table>
                            </Box>
                            {esic?.status_esic?.recursos_esic?.length > 0 && (
                              <Box>
                                <Typography className={classes.titleInfo}>
                                  Histórico de recursos
                                </Typography>
                                <Table size='small' aria-label="purchases" style={{ marginBottom: 30 }}>
                                  {esic?.status_esic?.recursos_esic?.map((recurso, index) => (
                                    <Fragment key={`${recurso.id}-key`}>
                                      <TableRow>
                                        <StyledTableCell align="left">
                                          {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {`${format(
                                            new Date(recurso.created_at),
                                            'dd/MM/yyyy',
                                          )} às ${format(
                                            new Date(recurso.created_at),
                                            'HH:mm',
                                          )}`}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {recurso.instancia}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {recurso.decisao}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => expandTableResource(index)}
                                          >
                                            {showDetailsTableResource === index ? (
                                              <MdExpandLess />
                                            ) : (
                                              <MdExpandMore />
                                            )}
                                          </IconButton>
                                        </StyledTableCell>
                                      </TableRow>
                                      <TableRow>
                                        <StyledTableCell
                                          style={{ paddingBottom: 0, paddingTop: 0 }}
                                          colSpan={6}
                                        >
                                          <Collapse
                                            in={index === showDetailsTableResource}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <Box margin="10px 0px">
                                              <Typography className={classes.titleInfo}>
                                                Descrição
                                              </Typography>
                                              <Typography className={classes.description}>
                                                {recurso.justificativa || 'Sem resultados.'}
                                              </Typography>
                                              {
                                                recurso?.anexos_recurso_esic?.length > 0 && (
                                                  <>
                                                    <Typography
                                                      className={classes.titleInfo}
                                                    >
                                                      Anexos
                                                    </Typography>
                                                    <Box
                                                      display="flex"
                                                      flexDirection="column"
                                                      alignItems="flex-start"
                                                      style={{
                                                        gap: 10,
                                                      }}
                                                    >
                                                      {recurso?.anexos_recurso_esic?.map(
                                                        (anexo) => (
                                                          <a
                                                            href={anexo.arquivo}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                          >
                                                            {anexo.titulo}
                                                          </a>
                                                        ),
                                                      )}
                                                    </Box>
                                                  </>
                                                )}
                                            </Box>
                                          </Collapse>
                                        </StyledTableCell>
                                      </TableRow>
                                    </Fragment>
                                  ))}
                                </Table>
                              </Box>
                            )}
                            {contentEvaluation(avaliacaoPendente)}
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    </Fragment>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {showModalResponse && esicId && (
          <ModalResponse
            handleClose={handleOpenModalResponse}
            open={showModalResponse}
            onReload={getEsicList}
            esicId={esicId}
          />
        )}
        {avaliacaoPendente &&
          (<>
            <EvaluationDialog
              open={openDialogEvaluation}
              handleCloseOrOpenDialog={handleCloseDialog}
              type={avaliacaoPendente?.tipo_demanda === "Solicitação" ? 'solicitacao_servico' : avaliacaoPendente?.tipo_demanda === 'Manifestação' ? 'manifestacao' : avaliacaoPendente?.tipo_demanda === 'E-SIC' ? 'esic' : 'agendamento'}
              evaluationIndicator={avaliacaoPendente?.id}
            //handleUpdated={onUpdatedList}
            />
          </>)}
        {showModalResponseRestituicao &&
          restitutionSelected &&
          descriptionRestitution &&
          esicId && (
            <ModalResponseRestituicao
              handleClose={handleOpenModalResponseRestituicao}
              open={showModalResponseRestituicao}
              restitutionData={restitutionSelected}
              legend={descriptionRestitution}
              onReload={getEsicList}
              esic_id={esicId}
            />
          )}

      </Container>

    </>
  )
}

export default EsicInfo