import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Button,
  Collapse,
  CircularProgress,
} from '@material-ui/core';
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { createStyles, withStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import ModalSearchManifestation from '../../../MyManifestation/components/ModalSearchManifestation';
import ModalResponse from '../../../MyManifestation/components/ModalResponse';
import { EvaluationDialog } from '../../../../Components/EvaluationDialog';
import { Star } from '@material-ui/icons';
import { IoStarSharp } from 'react-icons/io5';

export interface RestitutionSelected {
  solicitacao: number;
  id: string;
}

export interface TypeFilter {
  title: string;
  value: string;
}

export interface StatusData {
  anexos_status: [{
    arquivo: string;
    id: string;
    titulo: string;
  }];
  descricao: string;
  historico_status: {
    created_at: string;
    descricao: string;
    ordem: number;
    orgao_nome: string;
    status: string;
  }[];
  status: string;
  updated_at: string;
}


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#d3d3d3",
      },
    },
    "& .MuiSelect-iconOutlined": {
      height: 24,
      width: 24,
    },
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxIndicatorsBack: {
    width: "100%",
    margin: "20px 0px",
  },
  indicatorsBack: {
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
    display: 'flex',
  },
  textCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 16,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      textAlign: "center",
    },
  },
  button: {
    height: 56,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.7',
    }
  },
  status: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 500,
    textTransform: 'capitalize',
    padding: '5px 16px',
    borderRadius: 25,
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
      alignSelf: 'center',
    },
  },
  titleInfo: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    margin: '24px 0px'
  },
  textTable: {
    color: '#333333',
  },
  attachText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textDecoration: 'none',
    color: '#0F6FB7',
  },
  textLink: {
    margin: '5px 0px 5px 14px',
    textDecoration: 'none',
    color: '#0F6FB7'
  },
  iconRequests: {
    height: 24,
    width: 24,
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    color: '#1F2937',
    marginBottom: 10,
  },
  value: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    color: '#1F2937',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  boxStatus: {
    alignContent: 'center',
    maxWidth: 150,
    textAlign: 'center',
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    color: '#1F2937',
    fontWeight: 400,
    lineHeight: '20.02px',
  },
  openModalLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: '20.02px',
  },
  icon: {
    width: 18,
    height: 18,
    color: '#053396',
  },
  filter: {
    width: 250,
    color: "#373F41",
  },
  input: {
    color: '#333',
  },
  containerEvaluation: {
    display: 'flex',
    gap: 10,
  },
  boxStar: {
    display: 'flex',
    gap: 10,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 24,
    alignItems: 'center',
  },
  starIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20)
  },
  starCountText: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    textAlign: 'left',
    color: '#373F41',
  },
  iconButton: {
    fontSize: theme.typography.pxToRem(25),
    color: '#fff',
    marginRight: '5px'
  },
  buttonAction: {
    position: 'absolute',
    marginTop: '-70px',
    padding: '5px 15px'
  },
  fixedButtonBox: {
    width: '100%',
    justifyContent: 'end',
    display: 'flex',
  },
  titleFixedButton: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily: 'Roboto',
    fontWeight: 400,
    textTransform: 'uppercase'
  }
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgba(15, 111, 183, 0.05)",
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 500,
      fontFamily: 'Roboto',
    },
    body: {
      fontSize: 14,
      color: '#333333'
    },
  })
)(TableCell);



const ManifestationInfo = ({ list, getManifestationList, avaliacaoPendente }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showDetailsTable, setShowDetailsTable] = useState<number | null>();
  const [ticket, setTicket] = useState<number | null>();
  const [openToModal, setOpenToModal] = useState<boolean>(false);
  const [showModalResponse, setShowModalResponse] = useState<boolean>(false);
  const [restitutionSelected, setRestitutionSelected] = useState<RestitutionSelected | null>();
  const [descriptionRestitution, setDescriptionRestitution] = useState<string | null>();
  const [manifestationId, setManifestationId] = useState<number | null>(null);
  const [showModalCancel, setShowModalCancel] = useState<boolean>(false);

  const history = useHistory();



  const [openDialogEvaluation, setOpenDialogEvaluation] = useState(false);
  const [isPermittedEvaluate, setIsPermittedEvaluate] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


  const handleCloseDialog = useCallback(() => {
    setOpenDialogEvaluation(false);
  }, []);


  const expandTable = useCallback(
    (index: number) => {
      if (showDetailsTable === index) {
        setShowDetailsTable(null);
      } else {
        setShowDetailsTable(index);
      }
    },
    [showDetailsTable],
  );

  const handleOpenOrCloseToModal = useCallback(() => setOpenToModal((oldStateModal) => !oldStateModal), []);

  const handleOpenModalResponse = useCallback(() => {
    setShowModalResponse((oldShowModal) => !oldShowModal);
  }, []);

  const handleGetDescriptionRestitution = useCallback((status: StatusData) => {
    let descriptionRestitution = status?.status === 'Complementação Cidadão' && status.descricao;
    setDescriptionRestitution(descriptionRestitution);
  }, []);

  const handleModal = useCallback(() =>
    setShowModalCancel((oldModal) => !oldModal), []);

  const isPermittedCancel = useMemo(() => !(list?.results[0]?.status_demanda?.status === 'Concluído' ||
    list?.results[0]?.status_demanda?.status === 'Arquivado' ||
    list?.results[0]?.status_demanda?.status === 'Cancelado'), [list?.results[0]?.status_demanda]);

    const contentEvaluation = useCallback((avaliacaoPendente) => {
      return (
        <Fragment>
          {!!avaliacaoPendente ? (
            <>
              {!avaliacaoPendente?.[0]?.pendente ? (
                <Box className={classes.containerEvaluation}>
                  <Box>
                    <Typography className={classes.titleInfo}>
                      Avaliação do Serviço
                    </Typography>
                    <span className={classes.boxStar}>
                      <Star className={classes.starIcon} />
                      <Typography className={classes.starCountText}>
                        {avaliacaoPendente?.[0]?.avaliacao_consumo || '0'} estrelas
                      </Typography>
                    </span>
                  </Box>
                </Box>
              ) : (
                <Typography className={classes.titleInfo}>
                  Avaliação pendente
                </Typography>
              )}
            </>
          ) : (
            <Typography className={classes.titleInfo}>
              Avaliação indisponível
            </Typography>
          )}
        </Fragment>
      )
    }, [classes]);


  const handleCloseMenuAndDialog = () => {

    setAnchorEl(null);
    setOpenDialogEvaluation(true)
  };

  useEffect(() => {
    if (avaliacaoPendente?.[0]?.pendente) {
      setIsPermittedEvaluate(true)
      setTicket(avaliacaoPendente?.[0]?.id)
    }
  }, [avaliacaoPendente])

  return (
    <>

      <Container>

        {!list ? (
          <Box
            marginBottom="60px"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            < Box className={classes.fixedButtonBox} >
              {(list.results[0]?.avaliacao_manifestacao?.pendente || isPermittedCancel) && (
                list.results[0]?.status?.restituicao_manifestacao.map((restituicao) => {
                  if (!restituicao.respondido) {
                    return (
                      <Button
                        variant="contained"
                        color="primary" className={classes.buttonAction} onClick={() => {
                          handleOpenModalResponse();
                          handleGetDescriptionRestitution(list.results[0].status);
                          setManifestationId(list.results[0].id);
                          setRestitutionSelected({
                            solicitacao: restituicao.historico,
                            id: restituicao.id,
                          })
                        }} >
                        <MdEmail className={classes.iconButton} />

                        <Typography className={classes.titleFixedButton}
                        >
                          Responder
                        </Typography>
                      </Button>

                    )
                  }
                })
              )}
              {isPermittedEvaluate &&
                (<>
                  <Button
                    variant="contained"
                    color="primary" className={classes.buttonAction} onClick={() => history.push(`/workspace/avaliar/${ticket}`)}
                  >
                    <IoStarSharp className={classes.iconButton} />

                    <Typography className={classes.titleFixedButton}
                    >
                      Avaliar
                    </Typography>
                  </Button>
                </>)}
            </Box>
            {list?.results?.length && !loading && (
              <>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">

                    <TableBody>
                      {list?.results?.map((manifestation) => (
                        <Fragment key={manifestation.id}>
                          <TableRow>
                            <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                              <Box>
                                <Typography className={classes.titleInfo}>
                                  Informações
                                </Typography>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className={classes.textTable}>
                                        Protocolo
                                      </TableCell>
                                      {manifestation?.servico_titulo && (
                                        <TableCell className={classes.textTable}>
                                          Serviço
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell className={classes.textTable}>{manifestation.numero_processo}</TableCell>
                                      {manifestation?.servico_titulo && <TableCell className={classes.textTable}>{manifestation.servico_titulo}</TableCell>}
                                    </TableRow>
                                  </TableBody>
                                </Table>
                                <Typography className={classes.titleInfo}>
                                  Teor da Manifestação
                                </Typography>
                                <Box>
                                  <Typography className={classes.textTable}>
                                    Texto da Manifestação
                                  </Typography>
                                  <p>
                                    {manifestation.conteudo}
                                  </p>
                                </Box>
                                <Box>
                                  <Typography className={classes.textTable}>
                                    Protocolo relacionado
                                  </Typography>
                                  {manifestation.protocolo_relacionado ? (
                                    <p>
                                      {manifestation.protocolo_relacionado.join(', ')}
                                    </p>
                                  ) : (
                                    <p>Não informado</p>
                                  )}
                                </Box>
                                <Box>
                                  <Typography className={classes.textTable}>
                                    Anexo
                                  </Typography>
                                  {manifestation?.anexos.length > 0 ? (
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      gridGap="10px"
                                    >
                                      {manifestation?.anexos.map((anexo) => (
                                        <a
                                          className={classes.value}
                                          key={anexo.id}
                                          href={anexo.arquivo}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {anexo.titulo}
                                        </a>
                                      ))}
                                    </Box>
                                  ) : (
                                    <span className={classes.value}>Sem anexos</span>
                                  )}
                                </Box>
                                {/* <Table size="small" aria-label="purchases" style={{ marginBottom: 30 }}>
                                      <TableHead>
                                        <TableRow style={{ textTransform: 'uppercase' }}>
                                          <TableCell >Texto da Manifestação</TableCell>
                                          <TableCell className={classes.textTable}>serviço</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow style={{ textTransform: 'uppercase' }}>
                                          <TableCell component="th" scope="row" className={classes.textTable}>
                                            {manifestation.protocolo} teste
                                          </TableCell>
                                          <TableCell className={classes.textTable}>
                                            {manifestation.flow?.titulo}
                                            teste
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table> */}
                                {/* <Box style={{ margin: '20px 0px' }}>
                                      <Typography className={classes.titleInfo}>
                                        Documentos
                                      </Typography>
                                      {manifestation?.documento_resposta?.map((document, index) => (
                                        <Typography className={classes.attachText} key={index.toString()}>
                                          <MdAttachFile className={classes.iconRequests} />
                                          <Link href={`/workspace/meus_servicos/view_doc/${document.id}/${request.identificador}`} target='_blanck' className={classes.textLink}>{document.assunto}</Link></Typography>))}


                                      {request?.documento_resposta?.length === 0 && (<Typography className={classes.attachText}>Sem documentos</Typography>)}

                                    </Box> */}
                                <Box>
                                  <Typography className={classes.titleInfo}>
                                    Histórico da Manifestação
                                  </Typography>
                                  <Table size="small" aria-label="purchases" style={{ marginBottom: 30 }}>
                                    {manifestation?.status?.historico_status?.map(
                                      (historico, index) => (
                                        <Fragment key={`${historico.ordem}-key`}>
                                          <TableRow>
                                            <StyledTableCell align="left">
                                              {historico.ordem}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                              {`${format(
                                                new Date(historico.created_at),
                                                'dd/MM/yyyy',
                                              )} às ${format(
                                                new Date(historico.created_at),
                                                'HH:mm',
                                              )}`}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                              {historico.orgao_nome ?? 'Teste'}
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="left">
                                                  {manifestationData?.}
                                                </StyledTableCell> */}
                                            <StyledTableCell align="left">
                                              {
                                                historico.status === 'Aguardando' && (
                                                  <Box
                                                    className={classes.boxStatus}
                                                  >
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: '#FAAD13',
                                                        border: '1px solid rgba(250, 173, 19, 0.05)',
                                                        background: 'rgba(250, 173, 19, 0.05)',
                                                      }}
                                                    >
                                                      Aguardando
                                                    </Typography>
                                                  </Box>
                                                )
                                              }
                                              {
                                                historico.status === 'Em Andamento' && (
                                                  <Box
                                                    className={classes.boxStatus}
                                                  >
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: '#FAAD13',
                                                        border: '1px solid rgba(250, 173, 19, 0.05)',
                                                        background: 'rgba(250, 173, 19, 0.05)',
                                                      }}
                                                    >
                                                      Em Andamento
                                                    </Typography>
                                                  </Box>
                                                )
                                              }
                                              {historico.status === 'Arquivado' && (
                                                <Box
                                                  className={classes.boxStatus}
                                                >
                                                  <Typography
                                                    className={classes.status}
                                                    style={{
                                                      color: '#F03D3D',
                                                      border: '1px solid rgba(240, 61, 61, 0.08)',
                                                      background: 'rgba(240, 61, 61, 0.08)',
                                                    }}
                                                  >
                                                    Arquivado
                                                  </Typography>
                                                </Box>
                                              )}
                                              {historico?.status === "Prorrogado" && (
                                                <StyledTableCell align="center">
                                                  <Typography
                                                    className={classes.status}
                                                    style={{
                                                      color: "#FAAD13",
                                                      border:
                                                        "1px solid rgba(250, 173, 19, 0.05)",
                                                      background:
                                                        "rgba(250, 173, 19, 0.05)",
                                                    }}
                                                  >
                                                    Prorrogado
                                                  </Typography>
                                                </StyledTableCell>
                                              )}
                                              {historico.status === 'Cancelado' && (
                                                <Box
                                                  className={classes.boxStatus}
                                                >
                                                  <Typography
                                                    className={classes.status}
                                                    style={{
                                                      color: '#F03D3D',
                                                      border: '1px solid rgba(240, 61, 61, 0.08)',
                                                      background: 'rgba(240, 61, 61, 0.08)',
                                                    }}
                                                  >
                                                    Cancelado
                                                  </Typography>
                                                </Box>
                                              )}
                                              {historico.status === 'Encaminhado' && (
                                                <Box
                                                  className={classes.boxStatus}
                                                >
                                                  <Typography
                                                    className={classes.status}
                                                    style={{
                                                      color: '#053396',
                                                      border: '1px solid rgba(5, 51, 150, 0.05)',
                                                      background: ' rgba(5, 51, 150, 0.05)',
                                                    }}
                                                  >
                                                    Encaminhado
                                                  </Typography>
                                                </Box>
                                              )}
                                              {historico.status === 'Em Aberto' && (
                                                <Box
                                                  className={classes.boxStatus}
                                                >
                                                  <Typography
                                                    className={classes.status}
                                                    style={{
                                                      color: '#053396',
                                                      border: '1px solid rgba(5, 51, 150, 0.05)',
                                                      background: ' rgba(5, 51, 150, 0.05)',
                                                    }}
                                                  >
                                                    Em Aberto
                                                  </Typography>
                                                </Box>
                                              )}
                                              {historico.status === 'Reaberto' && (
                                                <Box
                                                  className={classes.boxStatus}
                                                >
                                                  <Typography
                                                    className={classes.status}
                                                    style={{
                                                      color: '#053396',
                                                      border: '1px solid rgba(5, 51, 150, 0.05)',
                                                      background: ' rgba(5, 51, 150, 0.05)',
                                                    }}
                                                  >
                                                    Reaberto
                                                  </Typography>
                                                </Box>
                                              )}
                                              {historico.status === 'Recebido' && (
                                                <Box
                                                  className={classes.boxStatus}
                                                >
                                                  <Typography
                                                    className={classes.status}
                                                    style={{
                                                      color: '#053396',
                                                      border: '1px solid rgba(5, 51, 150, 0.05)',
                                                      background: ' rgba(5, 51, 150, 0.05)',
                                                    }}
                                                  >
                                                    Recebido
                                                  </Typography>
                                                </Box>
                                              )}
                                              {historico.status === 'Respondido' && (
                                                <Box
                                                  className={classes.boxStatus}
                                                >
                                                  <Typography
                                                    className={classes.status}
                                                    style={{
                                                      color: '#3CD278',
                                                      border: '1px solid rgba(60, 210, 120, 0.05)',
                                                      background: 'rgba(60, 210, 120, 0.05)',
                                                    }}
                                                  >
                                                    Respondido
                                                  </Typography>
                                                </Box>
                                              )}
                                              {historico.status === 'Concluído' && (
                                                <Box
                                                  className={classes.boxStatus}
                                                >
                                                  <Typography
                                                    className={classes.status}
                                                    style={{
                                                      color: '#3CD278',
                                                      border: '1px solid rgba(60, 210, 120, 0.05)',
                                                      background: 'rgba(60, 210, 120, 0.05)',
                                                    }}
                                                  >
                                                    Concluído
                                                  </Typography>
                                                </Box>
                                              )}
                                              {historico.status ===
                                                "Complementação Cidadão" && (
                                                  <Box
                                                    className={classes.boxStatus}
                                                  >
                                                    <Typography
                                                      className={classes.status}
                                                      style={{
                                                        color: "#053396",
                                                        border:
                                                          "1px solid rgba(5, 51, 150, 0.05)",
                                                        background:
                                                          " rgba(5, 51, 150, 0.05)",
                                                      }}
                                                    >
                                                      Complementação Cidadão
                                                    </Typography>
                                                  </Box>
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => expandTable(index)}
                                              >
                                                {showDetailsTable === index ? (
                                                  <MdExpandLess />
                                                ) : (
                                                  <MdExpandMore />
                                                )}
                                              </IconButton>
                                            </StyledTableCell>
                                          </TableRow>
                                          <TableRow>
                                            <StyledTableCell
                                              style={{ paddingBottom: 0, paddingTop: 0 }}
                                              colSpan={6}
                                            >
                                              <Collapse
                                                in={index === showDetailsTable}
                                                timeout="auto"
                                                unmountOnExit
                                              >
                                                <Box margin="10px 0px">
                                                  <Typography className={classes.titleInfo}>
                                                    Descrição
                                                  </Typography>
                                                  <Typography className={classes.description}>
                                                    {historico.descricao || 'Sem resultados.'}
                                                  </Typography>
                                                  {(historico.status === 'Respondido' || historico.status === 'Concluído')
                                                    && manifestation?.status?.anexos_status
                                                      .length > 0 && (
                                                      <>
                                                        <Typography
                                                          className={classes.titleInfo}
                                                        >
                                                          Anexos
                                                        </Typography>
                                                        <Box
                                                          display="flex"
                                                          flexDirection="column"
                                                          alignItems="flex-start"
                                                          style={{
                                                            gap: 10,
                                                          }}
                                                        >
                                                          {manifestation?.status?.anexos_status.map(
                                                            (anexo) => (
                                                              <a
                                                                href={anexo.arquivo}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                              >
                                                                {anexo.titulo}
                                                              </a>
                                                            ),
                                                          )}
                                                        </Box>
                                                      </>
                                                    )}
                                                </Box>
                                              </Collapse>
                                            </StyledTableCell>
                                          </TableRow>
                                        </Fragment>
                                      ),
                                    )}
                                  </Table>
                                </Box>
                                {contentEvaluation(avaliacaoPendente)}
                                </Box>
                            </StyledTableCell>
                          </TableRow>
                        </Fragment>
                      ))}

                    </TableBody>
                  </Table>
                </TableContainer>
                {showModalResponse && restitutionSelected && descriptionRestitution && (
                  <ModalResponse
                    handleClose={handleOpenModalResponse}
                    open={showModalResponse}
                    restitutionData={restitutionSelected}
                    legend={descriptionRestitution}
                    onReload={getManifestationList}
                    manifestation_id={manifestationId}
                  />
                )}
                {avaliacaoPendente &&
                  (<>
                    <EvaluationDialog
                      open={openDialogEvaluation}
                      handleCloseOrOpenDialog={handleCloseDialog}
                      type={avaliacaoPendente?.tipo_demanda === "Solicitação" ? 'solicitacao_servico' : avaliacaoPendente?.tipo_demanda === 'Manifestação' ? 'manifestacao' : avaliacaoPendente?.tipo_demanda === 'E-SIC' ? 'esic' : 'agendamento'}
                      evaluationIndicator={avaliacaoPendente?.id}
                    //handleUpdated={onUpdatedList}
                    />
                  </>)}
                <ModalSearchManifestation
                  handleModal={handleOpenOrCloseToModal}
                  openModal={openToModal}
                />
              </>
            )}
          </>)}
      </Container>
    </>
  )
}

export default ManifestationInfo