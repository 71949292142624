import { Button, Theme, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { FaBell, FaEnvelope, FaSms } from "react-icons/fa";
import { requestPostMarkedNotificationAsSeen } from "../../services/notifications";
import getCookie from "../../utils/getCookies";

interface DialogProps {
  onCloseModal: () => void;
  data: {
    id: string;
    titulo: string;
    texto: string;
    lido: boolean;
    tipo: string;
    created_at: string;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  allContent: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'fixed',
    width: '100vw',
    minHeight: '100vh',
    background: 'rgba(29,41,57,0.24)',
    zIndex: 10,
  },
  dialog: {
    display: 'flex',
    position: 'fixed',
    maxWidth: 600,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 16px)',
    },
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 6,
    paddingBlock: 32,
    paddingInline: 24,
    border: 0,
    zIndex: 20,
    marginTop: 32,
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: 24,
  },
  headerNotify: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    color: '#1D2939'
  },
  iconNotify: {
    color: theme.palette.primary.main,
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: 'rgba(29,41,57,0.8)',
  },
  sectionInformation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bodyContent: {
    width: '100%',
    borderRadius: 6,
    border: '1px #EAECF0 solid',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 350,
    paddingBlock: 24,
    paddingInline: 12,
    marginBottom: 24,
    overflow: 'auto',

    '& p:last-of-type:has(a)': {
      maxWidth: 530,
      margin: '0 auto',
      marginBottom: 10,

      '& > a': {
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
      }
    }
  },
  footerSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  buttonClose: {
    width: 91,
    height: 36,
    background: theme.palette.primary.main,
    borderRadius: 6,
    '&:hover': {
      background: theme.palette.primary.main,
    }
  }


}))
export function DialogNotify({ data, onCloseModal }: DialogProps): JSX.Element {
  const classes = useStyles();
  const token: string | null = getCookie("gov_access_token_sso");

  useEffect(() => {
    async function requestMarkedNotification() {
      if (data.lido === false) {
        await requestPostMarkedNotificationAsSeen({
          token,
          notificationId: data.id
        });
      }
    }
    requestMarkedNotification();

  }, [token, data])

  useEffect(() => {
    const handleAcessibility = (event: KeyboardEventInit) => {
      if (event.key === 'Escape') {
        onCloseModal();
      }
    };

    if (data) {
      document.addEventListener('keydown', handleAcessibility);
    }

    return () => {
      document.removeEventListener('keydown', handleAcessibility);
    };
  }, [data, onCloseModal]);
  return (
    <>
      <div className={classes.allContent} onClick={onCloseModal} />
      <dialog className={classes.dialog}>
        <div className={classes.mainContent}>
          <section className={classes.headerNotify}>
            {data.tipo === "EMAIL" && <FaEnvelope size={40} className={classes.iconNotify} />}
            {data.tipo === "SMS" && <FaSms size={40} className={classes.iconNotify} />}
            {data.tipo === "PUSH" && <FaBell size={40} className={classes.iconNotify} />}
            <div className={classes.sectionInformation}>
              <h1 className={classes.title}>{data.titulo}</h1>
            </div>
          </section>

          <div dangerouslySetInnerHTML={{ __html: data.texto }} className={classes.bodyContent} />
          <div className={classes.footerSection}>
            <Button onClick={onCloseModal} className={classes.buttonClose}>
              FECHAR
            </Button>
          </div>
        </div>
      </dialog>
    </>

  );
}