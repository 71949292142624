/* eslint-disable array-callback-return */
import React, { useMemo, useState, useCallback, useEffect } from "react";

import { MdCancel, MdMoreVert, MdRemoveRedEye, MdContentPaste } from "react-icons/md";
import {
  Box,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
} from "@material-ui/core";
import { Resultado, TypeFilter, Dados } from "../..";

import ModalCancel from "../../../../Components/ModalCancel";
import { useToastValue } from "../../../../contexts/toastMessage";
import { AllDemandAuth, allManifestation, ombudsmanCancel } from "../../../../services/ouvidoria";

import { useHistory } from 'react-router';
import { allEsic } from "../../../../services/esic";
import { getAvaliaçãoById, getRequests, resendSolicitation } from "../../../../services/form-flow";
import { IoMdStar } from "react-icons/io";
import { EvaluationDialog } from "../../../../Components/EvaluationDialog";
import getCookie from "../../../../utils/getCookies";
import { historico } from "../../../../services/agendamento";
import { CancelDialog } from "../../../AttendenceRequest/components";
import FormDialog from "../FormDialog";
import CancelFormDialog from "../CancelForm";
import { IoReload } from "react-icons/io5";
import ModalResend from "../../../../Components/ModalResend";

interface Props {
  request: Resultado;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxActionsIcon: {
    width: 40,
    height: 40,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 1,
  },
  icon: {
    height: 24,
    width: 24,
  },
  iconCancel: {
    height: 24,
    width: 24,
    color: '#f00'
  },
  iconResend: {
    height: 24,
    width: 24,
    color: theme.palette.primary.main,
  },
  iconMoreInfo: {
    height: 24,
    width: 24,
    color: '#373F41'
  },
  links: {
    display: 'flex',
    gap: 10,
    fontFamily: 'Roboto',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    color: '#373F41',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(13),
    color: '#373F41',
    fontSize: theme.typography.pxToRem(16),
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  menuIcon: {
    fontSize: theme.typography.pxToRem(20),
  },
  menuIconCancel: {
    fontSize: theme.typography.pxToRem(20),
    color: '#f00'
  }
}));

export default function ButtonAction({
  request,
}: Props): JSX.Element {
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);
  const [openModalResend, setOpenModalResend] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [avaliacaoPendente, setAvaliacaoPendente] = useState<Dados | null>();
  const { setToast } = useToastValue();
  const [id, setId] = useState();
  const [ticket, setTicket] = useState<string>();
  const [scheduleToCancel, setScheduleToCancel] = useState();
  const [form, setForm] = useState();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openCancelFormDialog, setOpenCancelFormDialog] = useState(false);

  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    handleRequest(request)
    verifyPendingEvaluation()
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleCancelManifestation() {
    if (!id) return;
    try {
      setIsLoading(true);
      const { data } = await ombudsmanCancel(id, request.tipo === 'E-SIC' ? 'Esic' : request.tipo, request.para);
      setIsLoading(false);
      if (data) {
        handleOpenModalCancel();
        setToast({
          visible: true,
          message: 'Cancelamento realizado com sucesso!',
          type: 'success'
        });
      }
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setToast({
        visible: true,
        message: 'Cancelamento não foi realizado!',
        type: 'error'
      });
    }

  }

  async function handleResendSolicitation() {
    try {
      setIsLoading(true);
      const { data } = await resendSolicitation(request.protocolo);
      setIsLoading(false);
      if (data) {
        handleOpenModalResend();
        setToast({
          visible: true,
          message: 'Reenvio de solicitação realizado com sucesso!',
          type: 'success'
        });
      }
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setToast({
        visible: true,
        message: 'Reenvio de solicitação não foi realizado!',
        type: 'error'
      });
    }

  }

  const handleOpenModalCancel = useCallback(() =>
    setOpenModalCancel((oldModal) => !oldModal), []);

  const handleOpenModalResend = useCallback(() =>
    setOpenModalResend((oldModal) => !oldModal), []);

  const isPermittedCancel = useMemo(() => !(request?.status === 'Concluído' ||
    request?.status === 'Arquivado' ||
    request?.status === 'Cancelado' || request?.status === 'Finalizado'
    || request?.status === 'Cancelado pelo atendente' || request?.status === 'Cancelado pelo usuário'), [request]);

  const getManifestationList = async ({ page, filtro }: { page?: number, filtro?: TypeFilter }) => {
    try {
      const { data } = await allManifestation(page, filtro?.title, filtro?.value);
      setId(data?.results[0]?.id);
    } catch (error) {
      console.log(error);
    }
  }

  const getFlowList = async ({ page, protocolo }: { page: number; protocolo: string; }) => {
    try {
      const { data } = await getRequests(page, protocolo);

      setForm(data.results[0])
      setId(data?.results[0]?.id);
    } catch (error) {
      console.log(error);
    }
  }
  const getEsicList = async ({ page, filtro }: { page?: number, filtro?: { title: string; value: string } }) => {
    try {
      const { data } = await allEsic(page, filtro?.title, filtro?.value);
      setId(data?.results[0]?.id);
    } catch (error) {
      console.log(error);
    }
  }
  const getDemandsList = async ({ page, filtro }: { page?: number, filtro?: { title: string; value: string } }) => {
    try {
      const { data } = await AllDemandAuth(page, filtro?.title, filtro?.value);
      setId(data?.results[0]?.id);
    } catch (error) {
      console.log(error);
    }
  };
  const getMyAttendences = async (
    page: number,
    params?: any
  ): Promise<void> => {
    const token: string | null = getCookie(
      "gov_access_token_sso"
    );
    try {
      const { data } = await historico(token, page, '', params);
      setScheduleToCancel(data.results[0])
      setId(data?.results[0]?.id);
    } catch (err) {
      console.log(err);
    }

  };

  const handleRequest = (request) => {
    if (isPermittedCancel) {
      switch (request.tipo) {
        case 'E-SIC':
          var filterData = {
            title: 'protocolo',
            value: request.protocolo
          };
          getEsicList({ page: 1, filtro: filterData });
          break;
        case 'Manifestação':
          var filterData = {
            title: 'numero_processo',
            value: request.protocolo
          };
          getManifestationList({ page: 1, filtro: filterData });
          break;
        case 'Solicitação':
          var filterData = {
            title: 'protocolo',
            value: request.protocolo
          };
          if (request.para === 'Digital') {
            getFlowList({ page: 1, protocolo: filterData.value });
          } else {
            getDemandsList({ page: 1, filtro: filterData });
          }
          break;
        case 'Agendamento':
          const filtro = {
            protocolo: request.protocolo
          };
          getMyAttendences(1, filtro);
          break;
        default:
          break;
      }
    }
  };


  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setScheduleToCancel(undefined);
  };
  const handleCloseFormDialog = () => {
    setOpenFormDialog(false);
  };
  const handleCloseCancelFormDialog = () => {
    setOpenCancelFormDialog(false);
  };

  const getAvaliação = async (id: string) => {
    try {
      const { data } = await getAvaliaçãoById(id);
      setAvaliacaoPendente(data);
    } catch (error) {
      console.log(error);
    }
  };


  const verifyPendingEvaluation = () => {
    request.avaliacao.map((avaliacao) => {
      if (avaliacao.pendente === true) {
        getAvaliação(avaliacao.id)
        setTicket(avaliacao.id)
      }
    })
  }

  const verifySolicitation = (request: Resultado) => {
    if (request.tipo === 'Solicitação' && request.para === 'Digital') {
      return 'Solicitação-Flow';
    }

    return request?.tipo;
  }

  if (request.status === "Falha") return (
    <>

      <Box>
        <Box className={classes.boxActionsIcon}>
          <IconButton
            aria-label="comments"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MdMoreVert className={classes.icon} style={{ color: "#fff" }} />
          </IconButton>
        </Box>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleOpenModalResend();
              handleClose();
            }}>
            <Link className={classes.links}>
              <IoReload className={classes.iconResend} />
              Reenviar solicitação
            </Link>
          </MenuItem>
        </Menu>
        <ModalResend
          handleClose={handleOpenModalResend}
          open={openModalResend}
          legendTitle='Deseja realmente reenviar a solicitação?'
          isLoading={isLoading}
          onRequestResend={handleResendSolicitation}
        />
      </Box >
    </>
  )

  return (
    <>

      <Box>
        <Box className={classes.boxActionsIcon}>
          <IconButton
            aria-label="comments"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MdMoreVert className={classes.icon} style={{ color: "#fff" }} />
          </IconButton>
        </Box>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {request.tipo && request.status !== 'Em Preenchimento' && (
            <MenuItem
              onClick={() =>
                history.push(`/workspace/minhas_solicitacoes/${verifySolicitation(request)}/${request.protocolo}`)
              }
            >
              <Link className={classes.links}>
                <MdRemoveRedEye className={classes.iconMoreInfo} />
                Mais informações
              </Link>
            </MenuItem>
          )}
          {request.tipo === 'Agendamento' && isPermittedCancel && (
            <MenuItem
              onClick={() => setOpenCancelDialog(true)}
            >
              <Link className={classes.links}>
                <MdCancel className={classes.iconCancel} />
                Cancelar agendamento
              </Link>
            </MenuItem>
          )}
          {request.status === 'Em Preenchimento' && (
            <MenuItem
              onClick={() => setOpenFormDialog(true)}
            >
              <Link className={classes.links}>
                <MdContentPaste className={classes.iconMoreInfo} />
                Acessar formulário
              </Link>
            </MenuItem>
          )}
          {request.status === 'Em Preenchimento' && (
            <MenuItem
              onClick={() => setOpenCancelFormDialog(true)}
            >
              <Link className={classes.links}>
                <MdCancel className={classes.iconCancel} />
                Encerrar formulário
              </Link>
            </MenuItem>
          )}
          {isPermittedCancel && request.tipo !== 'Agendamento' && request.status !== 'Em Preenchimento' && request?.permitir_cancelamento_cidadao &&
            (<>
              <MenuItem
                onClick={() => {
                  handleOpenModalCancel();
                  handleClose();
                }}>
                <Link className={classes.links}>
                  <MdCancel className={classes.iconCancel} />
                  Cancelar solicitação
                </Link>
              </MenuItem>
            </>)}
          {avaliacaoPendente &&
            (<>
              <MenuItem className={classes.menuItem} onClick={(() => { history.push(`/workspace/avaliar/${ticket}`) })}>
                <IoMdStar className={classes.menuIcon} />
                <span>Avaliar consumo</span>
              </MenuItem>
            </>)}


        </Menu>
        <ModalCancel
          handleClose={handleOpenModalCancel}
          open={openModalCancel}
          legendTitle='Deseja realmente cancelar a manifestação?'
          isLoading={isLoading}
          onRequestCancel={handleCancelManifestation}
        />
        <FormDialog
          open={openFormDialog}
          handleClose={handleCloseFormDialog}
          form={form}
        />
        <CancelFormDialog
          open={openCancelFormDialog}
          handleClose={handleCloseCancelFormDialog}
          form={form}
        />
        {scheduleToCancel && (
          <CancelDialog
            open={openCancelDialog}
            attendence={scheduleToCancel}
            handleClose={handleCloseCancelDialog}
            onReload={getMyAttendences}
          />
        )}
      </Box >

    </>
  );
}


