import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Grid,
  Container,
  Card,
  CardContent,
  IconButton,
  Button,
  Paper,
  Dialog,
  DialogContent,
  Slider,
  DialogActions,
  CircularProgress,
  useMediaQuery,
  Tab,
  Tabs,
  Avatar,
} from "@material-ui/core";
import { MdArrowBack, MdCheckCircleOutline } from "react-icons/md";
import { Breadcrumb, NavMenu } from "../../Components";
import { useEffect } from "react";
import { useState } from "react";
import {
  UserData,
  UserDataAdicional,
  DadosComplementares,
} from "../../interfaces/user";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./cropImage";
import { useToastValue } from "../../contexts/toastMessage";
import {
  sendImageProfile,
  getImageProfile,
  getAdditionalDataUserSso,
  getPreferencias,
  sendPreferencias,
  sendDependentes,
  sendAdditionalData,
  getUserDataAdicional as getDataAdicional,
  getDependentes,
} from "../../services/auth";
import { useHistory } from "react-router-dom";
import { addDays, set } from "date-fns";
import AccordionDadosPessoais from "./Components/AccordionDadoPessoais";
import AccordionDependentes from "./Components/AccordionDependentes";
import AccordionPreferencias from "./Components/AccordionPreferencias";
import getCookie from "../../utils/getCookies";
import { MdAddAPhoto } from "react-icons/md";
import { deleteDependentes } from "../../services/auth";
import { removeCpfMask, validateCpf } from "../../utils/cpfFormat";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#d3d3d3",
      },
    },
    "& .MuiSelect-iconOutlined": {
      height: 24,
      width: 24,
    },
  },
  colorInputText: {
    color: theme.palette.primary.main,
  },
  gridMain: {
    margin: 0,
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxIndicatorsBack: {
    width: "100%",

    justifyContent: "center",
    margin: "20px 0px",
  },
  indicatorsBack: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  cardData: {
    // maxWidth: 680,
    width: "100%",
    backgroundColor: "#FFFFFF",
    paddingTop: 40,
    paddingLeft: 50,
    paddingRight: 50,
  },
  boxAvatar: {
    marginTop: 20,
    border: `2px solid ${theme.palette.primary.main}`,
    width: 100,
    height: 100,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    height: 88,
    width: 88,
    backgroundColor: "#E1EDF6",
  },
  userName: {
    fontSize: 16,
    color: "#373F41",
    fontWeight: "normal",
    paddingTop: 16,
    fontFamily: "Roboto",
  },
  userCpf: {
    fontSize: 16,
    color: "#373F41",
    fontWeight: "normal",
    padding: "4px 0px 24px",
    opacity: 0.5,
  },
  input: {
    display: "none",
  },
  button: {
    minWidth: 161,
    height: 44,
    fontSize: "100%",
    borderRadius: 4,
    padding: "12px 16px",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  labelButton: {
    fontSize: 14,
    fontFamily: "Roboto",
  },
  inputForm: {
    margin: "24px 0px",
    color: "#DDD",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: 5,
  },
  buttonReturnAndSave: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 16,
  },
  buttonReturn: {
    width: 83,
    marginRight: 16,
    borderRadius: 4,
    color: "#373F41",
    border: 0,
    textTransform: "uppercase",
  },
  buttonSave: {
    width: 83,
  },
  icon: {
    width: 24,
    height: 24,
  },
  hr: {
    border: "1px solid #E1E6EF",
  },
  iconExtends: {
    fontSize: 20,
    color: "#fff",
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  formControl: {
    // marginTop: 20,
    // marginBottom: 20,
    width: "100%",
  },
  buttonExpanded: {
    width: 40,
    height: 40,
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  },
  accordionTitle: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: "Roboto",
    color: "#333333",
  },
  divInstrucoes: {
    color: "#706E7A",
    marginLeft: 16,
    marginTop: 8,
  },
  tabs: {
    textTransform: "none",
    fontFamily: "Roboto",
    fontWeight: 400,
    color: "#706E7A",
    "& .Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const listaPcd = [
  {
    id: 0,
    option: "Não possui",
  },
  {
    id: 1,
    option: "Auditiva",
  },
  {
    id: 2,
    option: "Visual",
  },
  {
    id: 3,
    option: "Motora",
  },
  {
    id: 4,
    option: "Mental",
  },
  {
    id: 5,
    option: "Intelectual",
  },
  {
    id: 6,
    option: "Outro",
  },
];

export default function MyData(): JSX.Element {
  const classes = useStyles();
  const { setToast } = useToastValue();
  const [showPcd, setShowPcd] = useState<boolean>(false);
  const [pcd, setPcd] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserData | undefined>();
  const [dependentes, setDependentes] = useState();
  const [userDataAdditional, setUserDataAdditional] = useState<
    DadosComplementares | undefined
  >();
  const [errorNameDependente, setErrorNameDependente] = useState<
    undefined | string
  >(undefined);
  const [errorCpfDependente, setErrorCpfDependente] = useState<
    undefined | string
  >(undefined);
  const [userDataAdicional, setUserDataAdicional] =
    useState<UserDataAdicional | null>();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [crop, setCrop] = useState({ x: 100, y: 100 });
  const [savedImage, setSavedImage] = useState<boolean>(false);
  const [zoom, setZoom] = useState(1);
  const [blob, setBlob] = useState(null);
  const [avatarUser, setAvatarUser] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const userDataStorage: string | null = getCookie("gov_user_data");
  const userDataAdicionalLocal: string | null = getCookie(
    "gov_user_data_adicional"
  );
  const token: string | null = getCookie("gov_access_token_sso");
  const history = useHistory();
  const matchesMobile = useMediaQuery("(max-width:500px)");
  const matchesTablet = useMediaQuery("(max-width:770px)");
  // console.log(userDataAdicionalLocal, " userDataAdicionalLocal")

  // NEW DATA
  const [birthDate, setBirthDate] = useState<Date>();
  const [genero, setGenero] = useState("");
  const [raca, setRaca] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");
  const [escolaridade, setEscolaridade] = useState("");
  const [endereco, setEndereco] = useState("");

  const [telefone, setTelefone] = useState("");
  const [nacionalidade, setNacionalidade] = useState("");
  const [naturalidade, setNaturalidade] = useState("");
  const [nomeMae, setNomeMae] = useState("");
  const [nomePai, setNomePai] = useState("");
  const [nomeSocial, setNomeSocial] = useState("");
  const [identidadeDeGenero, setIdentidadeDeGenero] = useState("");
  const [pcdValue, setPcdValue] = useState("");
  const [otherPcd, setOtherPcd] = useState("");
  const [apelido, setApelido] = useState("");
  const [orientacaoSexual, setOrientacaoSexual] = useState("");
  const [quantidadeFilhos, setQuantidadeFilhos] = useState("");
  const [nomeDependente, setNomeDependente] = useState("");
  const [birthDateDependente, setBirthDateDependente] = useState<Date>();
  const [cpfDependente, setCpfDependente] = useState("");
  const [nomeConjuge, setNomeConjuge] = useState("");
  const [birthDateConjuge, setBirthDateConjuge] = useState<Date>();

  // NEW DATA ADDRESS
  const [tipoLogradouro, setTipoLogradouro] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numeroCasa, setNumeroCasa] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cep, setCep] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [uf, setUf] = useState("");
  const [loadingCep, setLoadingCep] = useState<boolean>(false);
  const [errorCep, setErrorCep] = useState<string | null>(null);

  // DEPENDENTES
  const [parentesco, setParentesco] = useState("");
  const [isDeficiente, setIsDeficiente] = useState<boolean>();
  const [emailDependente, setEmailDependente] = useState("");

  // Tabs
  const [valueTags, setValueTags] = React.useState(0);

  // ACCORDION PREFERENCIAS
  const [emailNotification, setEmailNotification] = useState(false);
  //const [smsNotification, setSmsNotification] = useState(false)
  const [isSave, setIsSave] = useState<boolean>(false);

  const handleChangeTags = (event: React.SyntheticEvent, newValue: number) => {
    setValueTags(newValue);
  };

  const getUserDataAdditional = async (token: string, cpf: string) => {
    try {
      const data = await getAdditionalDataUserSso(token, cpf);
      // console.log(data.data)
      setUserDataAdditional(data.data);
      document.cookie = `gov_user_data_adicional=${encodeURIComponent(
        JSON.stringify(data.data)
      )};path=/`;
    } catch (err) {
      console.log(err);
    }
  };
  const getUserPreferencias = async (token: string) => {
    try {
      const data = await getPreferencias(token);
      setEmailNotification(data.data.email);
    } catch (err) {
      console.log(err);
    }
  };

  const getUserDependentes = async (cpf: string) => {
    try {
      const data = await getDependentes(token, cpf);
      setDependentes(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeImage = (e) => {
    setOpenModal(true);
    const reader = new FileReader();
    if (e.target.files.length) {
      const fileArquive = e.target.files[0];
      reader.addEventListener(
        "load",
        () => {
          setImage({
            preview: URL.createObjectURL(fileArquive),
            raw: reader.result as string,
          });
        },
        false
      );
      e.target.value = "";
      if (fileArquive) {
        reader.readAsDataURL(fileArquive);
      }
    }
  };

  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage: Blob = await getCroppedImg(
      image.raw,
      croppedAreaPixels
    );
    setBlob(URL.createObjectURL(croppedImage));
    let file = new File([croppedImage], `avatar-${Date.now()}.png`, {
      lastModified: new Date().getTime(),
      type: "image/png",
    });

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      setFile(file);
    };
  };

  const handleSave = async () => {
    if (file) {
      try {
        setLoadingImage(true);
        const response = await sendImageProfile(token, file);

        if (response.status === 201) {
          setSavedImage(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingImage(false);
      }
    }
  };
  const removeDependente = async (id: number) => {
    try {
      await deleteDependentes(token, id);
      setLoading(false);
      setToast({
        visible: true,
        message: "Dados do dependente removido com sucesso!",
        type: "success",
      });
    } catch (error) {
      console.log(error);
      setToast({
        visible: true,
        message: "Não foi possível remover dados de dependente!",
        type: "error",
      });
    }
    setIsSave(false);
    setNomeDependente("");
    setEmailDependente("");
    setCpfDependente("");
    setParentesco("");
    setBirthDateDependente(new Date());
    getUserDependentes(userData.cpf);
  };

  const handleSaveAdditionalDatas = async () => {
    setLoading(true);
    // handleSave();
    setIsSave(true);
    const isOtherPcdEmpty =
      (showPcd && otherPcd.length > 0) || (!showPcd && pcdValue.length > 0);
    if (
      raca &&
      estadoCivil &&
      cep &&
      municipio &&
      uf &&
      birthDate &&
      endereco &&
      numeroCasa &&
      bairro &&
      nomeMae &&
      nomePai &&
      errorCep === null &&
      valueTags === 0 &&
      identidadeDeGenero &&
      orientacaoSexual &&
      pcdValue &&
      isOtherPcdEmpty
    ) {
      try {
        const complementar = {
          raca,
          estado_civil: estadoCivil,
          escolaridade,
          naturalidade,
          nacionalidade,
          pcd,
          tipo_pcd: pcd ? otherPcd || pcdValue : undefined,
          numero: numeroCasa,
          bairro,
          cidade: municipio,
          cep,
          estado: uf,
          endereco,
          complemento,
          apelido,
        };
        const contato = {
          telefone: telefone,
          email: userData.contato.email
            ? userData.contato.email
            : "Não informado",
        };
        const verifyGenero = genero === "Não informado" ? null : genero;
        await sendAdditionalData(
          token,
          userData.cpf,
          nomePai,
          nomeMae,
          nomeSocial,
          orientacaoSexual,
          birthDate,
          complementar,
          contato,
          identidadeDeGenero
        );
        await sendPreferencias(token, emailNotification);
        setLoading(false);
        setToast({
          visible: true,
          message: "Dados salvos com sucesso!",
          type: "success",
        });
        sessionStorage.setItem("@data-complements", "true");
      } catch (error) {
        console.log(error);
        setLoading(false);

        setToast({
          visible: true,
          message: "Não foi possível salvar seus dados!",
          type: "error",
        });
      } finally {
        setIsSave(false);
      }
    } else if (valueTags === 1) {
      if (nomeDependente && nomeDependente.length < 6) {
        setLoading(false);
        setErrorNameDependente("Nome deve ter no mínimo 6 caracteres.");
        return;
      }
      const cpfDependenteWithoutMask = removeCpfMask(cpfDependente);
      if (cpfDependenteWithoutMask) {
        if (
          !validateCpf(cpfDependenteWithoutMask) ||
          cpfDependenteWithoutMask.length < 11
        ) {
          setLoading(false);
          setErrorCpfDependente("CPF inválido.");
        }
      }
      try {
        await sendDependentes(
          token,
          nomeDependente,
          birthDateDependente,
          isDeficiente,
          parentesco,
          cpfDependente,
          emailDependente
        );
        setLoading(false);
        setToast({
          visible: true,
          message: "Dados do Dependente salvos com sucesso!",
          type: "success",
        });
        setIsSave(false);
        getUserDependentes(userData.cpf);
        setErrorCpfDependente(undefined);
      } catch (error) {
        console.log(error);
        setLoading(false);

        setToast({
          visible: true,
          message: "Não foi possível salvar os dados do dependente!",
          type: "error",
        });
      }
    } else if (valueTags === 2) {
      try {
        await sendPreferencias(token, emailNotification);
        setLoading(false);
        setToast({
          visible: true,
          message: "Preferência salva com sucesso!",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        setLoading(false);

        setToast({
          visible: true,
          message: "Não foi possível salvar sua preferência!",
          type: "error",
        });
      }
    } else {
      setLoading(false);
      setToast({
        visible: true,
        message: "Preencha os dados obrigatórios corretamente!",
        type: "error",
      });
    }
    if (token && userData?.cpf) {
      await getUserDataAdditional(token, userData.cpf);
    }
  };

  useEffect(() => {
    const cpf = JSON.parse(userDataStorage).cpf;

    if (userDataStorage) {
      setUserData(JSON.parse(userDataStorage));
    }

    if (Object.keys(userDataAdicionalLocal).length !== 0) {
      setUserDataAdditional(JSON.parse(userDataAdicionalLocal));
    } else {
      if (token && userData?.cpf) {
        getUserDataAdditional(token, cpf);
      }
    }
    getUserPreferencias(token);
    getUserDependentes(cpf);
  }, [token, userDataAdicionalLocal, userDataStorage, userData?.cpf]);

  useEffect(() => {
    if (
      userDataAdditional &&
      Object.keys(userDataAdditional).length !== 0 &&
      !isSave
    ) {
      setBirthDate(
        userDataAdditional?.data_nascimento
          ? addDays(new Date(userDataAdditional?.data_nascimento), 1)
          : null
      );
      setGenero(userDataAdditional?.sexo || "");
      setRaca(
        userDataAdditional?.complementar?.raca === "Não informado" ||
          !userDataAdditional?.complementar?.raca
          ? ""
          : userDataAdditional?.complementar?.raca
      );
      setEstadoCivil(userDataAdditional?.complementar?.estado_civil || "");
      setEscolaridade(
        userDataAdditional?.complementar?.escolaridade || "Não informado"
      );
      setEndereco(userDataAdditional?.complementar?.endereco || "");
      setNacionalidade(
        userDataAdditional?.complementar?.nacionalidade || "Não informado"
      );
      setNaturalidade(
        userDataAdditional?.complementar?.naturalidade || "Não informado"
      );
      setNomeMae(userDataAdditional?.nome_mae);
      setNomePai(userDataAdditional?.nome_pai);
      setNomeSocial(userDataAdditional?.nome_social);
      setApelido(userDataAdditional?.complementar?.apelido);
      setTelefone(userDataAdditional?.contato?.telefone);
      setOrientacaoSexual(userDataAdditional?.orientacao_sexual || "");
      setIdentidadeDeGenero(userDataAdditional?.identidade_genero || "");
      setPcd(userDataAdditional?.complementar?.pcd || false);
      setShowPcd(
        userDataAdditional?.complementar?.pcd &&
          (!listaPcd.some(
            (item) => item.option === userDataAdditional?.complementar?.tipo_pcd
          ) ||
            userDataAdditional?.complementar?.tipo_pcd === "Outro")
      );
      const isPcdOptionMatch = (item) =>
        item.option === userDataAdditional?.complementar?.tipo_pcd;

      if (!userDataAdditional?.complementar?.pcd) {
        setPcdValue("Não possui");
      } else if (
        listaPcd.some(
          (item) =>
            item.option.toLowerCase() ===
            userDataAdditional?.complementar?.tipo_pcd.toLowerCase()
        ) &&
        userDataAdditional?.complementar?.tipo_pcd.toUpperCase() !== "OUTRO"
      ) {
        const matchItem = listaPcd.find(
          (item) =>
            item.option.toLowerCase() ===
            userDataAdditional?.complementar?.tipo_pcd.toLowerCase()
        );
        setPcdValue(matchItem?.option);
      } else if (
        userDataAdditional?.complementar?.tipo_pcd.toUpperCase() === "OUTRO"
      ) {
        setPcdValue("Outro");
        setOtherPcd(userDataAdditional?.complementar?.tipo_pcd);
      } else if (!listaPcd.some((item) => isPcdOptionMatch(item))) {
        setPcdValue("Outro");
        setOtherPcd(userDataAdditional?.complementar?.tipo_pcd);
      } else {
        setPcdValue(userDataAdditional?.complementar?.tipo_pcd);
      }

      //DEPENDENTES
      //setQuantidadeFilhos("Não informado");
      //setNomeDependente("Não informado");
      //setBirthDateDependente(new Date());
      //setCpfDependente("Não informado");
      //setNomeConjuge("Não informado");
      //setBirthDateConjuge(new Date());
      //setParentesco("");
      //setEmailDependente(userData?.email_dependente || "Não informado");
      //setIsDeficiente(userData?.isDeficiente);
      //setParentesco(userData?.parentesco_dependente || "Não informado");

      // PREFERENCIAS
      setEmailNotification(emailNotification);
      //setSmsNotification(smsNotification)

      // ADDRESS
      setLogradouro(userDataAdditional?.complementar?.endereco || "");
      setNumeroCasa(userDataAdditional?.complementar?.numero);
      setComplemento(userDataAdditional?.complementar?.complemento);
      setBairro(userDataAdditional?.complementar?.bairro);
      setCep(userDataAdditional?.complementar?.cep);
      setMunicipio(userDataAdditional?.complementar?.cidade);
      setUf(userDataAdditional?.complementar?.estado);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataAdditional, isSave]);

  /* useEffect(() => {
     setQuantidadeFilhos("Não informado");
     setNomeDependente("Não informado");
     setBirthDateDependente(new Date());
     setCpfDependente("Não informado");
     setNomeConjuge("Não informado");
     setBirthDateConjuge(new Date());
     setParentesco("Não informado");
     setEmailDependente(userData?.email_dependente || "Não informado");
     //setIsDeficiente(userData?.isDeficiente);
     setParentesco(userData?.parentesco_dependente || "Não informado");
   }, [])*/

  useEffect(() => {
    const handleGetImageProfile = async () => {
      try {
        setLoadingImage(true);
        const { data } = await getImageProfile(token);

        if (data?.results?.length > 0) {
          const image = data.results[0].arquivo;
          setAvatarUser(image);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingImage(false);
      }
    };
    handleGetImageProfile();
  }, [token]);

  useEffect(() => {
    setPcd(pcdValue !== "Não possui");
  }, [pcdValue]);

  const linksBreadcrumb = [
    {
      name: "Dados básicos",
      redirectTo: "#",
    },
  ];

  return (
    <>
      {!matchesTablet && <NavMenu />}

      <Dialog open={savedImage} onClose={() => history.go(0)}>
        <DialogContent>
          <MdCheckCircleOutline
            style={{
              width: 60,
              height: 60,
              color: "#4bbd7e",
              textAlign: "center",
            }}
          />
          <Typography
            style={{ color: "#333", fontFamily: "Roboto", fontSize: 22 }}
          >
            Imagem de perfil salva com sucesso!!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.buttonSave}
            onClick={() => history.go(0)}
            variant="contained"
            color="primary"
            size="small"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      {image.preview && (
        <Dialog open={openModal}>
          <DialogContent>
            <Box width={500} height={474}>
              <Cropper
                image={image.preview}
                crop={crop}
                zoom={zoom}
                cropSize={{ width: 300, height: 300 }}
                cropShape="round"
                showGrid={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                maxZoom={5}
              />
            </Box>
          </DialogContent>
          <DialogActions
            style={{
              backgroundColor: "#fff",
              display: "flex",
              zIndex: 2,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box width="100%" padding="30px 50px">
              <Slider
                step={1}
                max={5}
                min={1}
                value={zoom}
                onChange={(e, newValue: any) => setZoom(newValue)}
                aria-labelledby="continuous-slider"
              />
            </Box>
            <Box display="flex" alignSelf="flex-end">
              <Button
                onClick={() => {
                  setOpenModal(false);
                  setImage({
                    preview: "",
                    raw: "",
                  });
                  setZoom(1);
                  setBlob(null);
                }}
                className={classes.buttonReturn}
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button
                autoFocus
                onClick={() => {
                  handleSave();
                  setOpenModal(false);
                }}
                className={classes.buttonSave}
                variant="contained"
                color="primary"
                size="small"
              >
                Salvar
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
      <Container>
        <Grid container className={classes.gridMain} spacing={3}>
          <Grid item xs={12}>
            <Breadcrumb links={linksBreadcrumb} />
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <IconButton onClick={() => history.push("/workspace")}>
                  <MdArrowBack className={classes.icon} />
                </IconButton>
                <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                  Dados básicos
                </Typography>
                <Paper square>
                  <Tabs
                    // value={selectTab}
                    indicatorColor="primary"
                    textColor="primary"
                    // variant='fullWidth'
                    aria-label="full width tabs example"
                    // onChange={handleChange}
                    // className={classes.tabsInfo}
                  ></Tabs>
                </Paper>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card
              style={{ padding: matchesTablet && "40px 0 0 0" }}
              className={classes.cardData}
            >
              <CardContent>
                <Box
                  display="flex"
                  style={{
                    gap: "20px",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box className={classes.boxAvatar}>
                    {loadingImage ? (
                      <CircularProgress />
                    ) : (
                      <Avatar
                        src={blob || avatarUser}
                        className={classes.avatar}
                      />
                    )}
                  </Box>
                  <div style={{ marginLeft: "16px" }}>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      onChange={handleChangeImage}
                      type="file"
                    />
                    <label
                      htmlFor="contained-button-file"
                      className={classes.labelButton}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        startIcon={
                          <MdAddAPhoto style={{ width: 20, height: 20 }} />
                        }
                        className={classes.button}
                        disabled={loadingImage}
                      >
                        ALTERAR FOTO
                      </Button>
                    </label>
                  </div>
                  <div
                    style={{ textAlign: matchesMobile ? "center" : "justify" }}
                    className={classes.divInstrucoes}
                  >
                    Dimensões recomendadas: 200 x 200, tamanho máximo do
                    arquivo: 5 MB
                  </div>
                </Box>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "100%",
                    marginTop: 20,
                  }}
                >
                  <Tabs
                    className={classes.tabs}
                    value={valueTags}
                    onChange={handleChangeTags}
                    centered
                    aria-label="basic tabs example"
                  >
                    <Tab
                      style={{
                        width: "100%",
                        textTransform: "none",
                        fontSize: 18,
                      }}
                      label="Dados"
                      {...a11yProps(0)}
                    />
                    {
                      <Tab
                        style={{
                          width: "100%",
                          textTransform: "none",
                          fontSize: 18,
                        }}
                        label="Dependentes"
                        {...a11yProps(1)}
                      />
                    }
                    <Tab
                      style={{
                        width: "100%",
                        textTransform: "none",
                        fontSize: 18,
                      }}
                      label="Preferências"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                {valueTags === 0 && (
                  <>
                    <AccordionDadosPessoais
                      userData={userData}
                      userDataAdditional={userDataAdditional}
                      birthDate={birthDate}
                      setBirthDate={setBirthDate}
                      raca={raca}
                      setRaca={setRaca}
                      naturalidade={naturalidade}
                      setNaturalidade={setNaturalidade}
                      nomeMae={nomeMae}
                      setNomeMae={setNomeMae}
                      genero={genero}
                      setGenero={setGenero}
                      estadoCivil={estadoCivil}
                      setEstadoCivil={setEstadoCivil}
                      nacionalidade={nacionalidade}
                      setNacionalidade={setNacionalidade}
                      nomePai={nomePai}
                      setNomePai={setNomePai}
                      nomeSocial={nomeSocial}
                      setNomeSocial={setNomeSocial}
                      orientacaoSexual={orientacaoSexual}
                      setOrientacaoSexual={setOrientacaoSexual}
                      identidadeDeGenero={identidadeDeGenero}
                      setIdentidadeDeGenero={setIdentidadeDeGenero}
                      listPcd={listaPcd}
                      pcd={pcd}
                      setPcd={setPcd}
                      pcdValue={pcdValue}
                      setPcdValue={setPcdValue}
                      showPcd={showPcd}
                      setShowPcd={setShowPcd}
                      otherPcd={otherPcd}
                      setOtherPcd={setOtherPcd}
                      apelido={apelido}
                      setApelido={setApelido}
                      escolaridade={escolaridade}
                      setEscolaridade={setEscolaridade}
                      blob={blob}
                      avatarUser={avatarUser}
                      handleChangeImage={handleChangeImage}
                      tipoLogradouro={tipoLogradouro}
                      setTipoLogradouro={setTipoLogradouro}
                      logradouro={logradouro}
                      setLogradouro={setLogradouro}
                      numeroCasa={numeroCasa}
                      setNumeroCasa={setNumeroCasa}
                      complemento={complemento}
                      setComplemento={setComplemento}
                      bairro={bairro}
                      setBairro={setBairro}
                      cep={cep}
                      setCep={setCep}
                      municipio={municipio}
                      setMunicipio={setMunicipio}
                      uf={uf}
                      setUf={setUf}
                      telefone={telefone}
                      setTelefone={setTelefone}
                      endereco={endereco}
                      setEndereco={setEndereco}
                      setErrorCep={setErrorCep}
                      errorCep={errorCep}
                      isSave={isSave}
                    />
                  </>
                )}
                {valueTags === 1 && (
                  <>
                    <AccordionDependentes
                      nomeConjuge={nomeConjuge}
                      setNomeConjuge={setNomeConjuge}
                      birthDateConjuge={birthDateConjuge}
                      setBirthDateConjuge={setBirthDateConjuge}
                      quantidadeFilhos={quantidadeFilhos}
                      setQuantidadeFilhos={setQuantidadeFilhos}
                      birthDateDependente={birthDateDependente}
                      setBirthDateDependente={setBirthDateDependente}
                      nomeDependente={nomeDependente}
                      setNomeDependente={setNomeDependente}
                      isDeficiente={isDeficiente}
                      setIsDeficiente={setIsDeficiente}
                      parentesco={parentesco}
                      setParentesco={setParentesco}
                      emailDependente={emailDependente}
                      setEmailDependente={setEmailDependente}
                      dependentes={dependentes}
                      setDependentes={setDependentes}
                      cpfDependente={cpfDependente}
                      setCpfDependente={setCpfDependente}
                      removeDependente={removeDependente}
                      errorCpfDependente={errorCpfDependente}
                      setErrorCpfDependente={setErrorCpfDependente}
                      errorNameDependente={errorNameDependente}
                      setErrorNameDependente={setErrorNameDependente}
                      isSave={isSave}
                      handleSaveAdditionalDatas={handleSaveAdditionalDatas}
                    />
                  </>
                )}
                {valueTags === 2 && (
                  <>
                    <AccordionPreferencias
                      setEmailNotification={setEmailNotification}
                      emailNotification={emailNotification}
                    />
                  </>
                )}

                <div
                  className={classes.buttonReturnAndSave}
                  style={{
                    justifyContent: matchesTablet && "end",
                    display: matchesMobile && "block",
                  }}
                >
                  {/* <IconButton
                    onClick={handleFormExtends}
                    className={classes.buttonExpanded}
                  >
                    {extendForms ? (
                      <MdOutlineKeyboardArrowUp size={60} />
                    ) : (
                      <MdOutlineKeyboardArrowDown size={60} />
                    )}
                  </IconButton> */}
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: valueTags === 1 ? "end" : "start",
                      width: "100%",
                    }}
                  >
                    {valueTags !== 1 && (
                      <>
                        <div>
                          <Button
                            variant="outlined"
                            className={classes.buttonReturn}
                            onClick={() => history.go(-1)}
                            style={{
                              display: matchesTablet && "none",
                            }}
                          >
                            VOLTAR
                          </Button>
                          {loading ? (
                            <CircularProgress color="primary" />
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              className={classes.buttonSave}
                              onClick={handleSaveAdditionalDatas}
                              style={{ width: matchesMobile && "100%" }}
                            >
                              SALVAR
                            </Button>
                          )}
                        </div>
                      </>
                    )}
                  </Box>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
